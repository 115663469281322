import {
  Button, Dialog, DialogActions, DialogContent,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell, TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import { setProgress } from '../../../redux/actions/progress';
import styles from './style';

const invertSort = {
  asc: "desc",
  desc: "asc"
}

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class DiplomaScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isNewOpen: false,
      isEditOpen: false,
      diplomas: [],
      dataSource: [],
      filteredDataSource: [],
      selectedItem: {},
      height: 400,
      rowsPerPage: 25,
      currentPage: 0,
      sortBy: '',
      sortDirection: 'desc'
    }

    this.onNewClose = this.onNewClose.bind(this);
    this.onEditClose = this.onEditClose.bind(this);
  }

  updateDimensions() {

    let update_height = 0;

    if (this.props.nnouncementAlert !== '') {
      update_height = window.innerHeight - 350;
    } else {
      update_height = window.innerHeight - 260;
    }

    this.setState({ height: update_height });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentDidMount() {

    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.setState({
      isLoading: true
    })

    this.getData();
    this.updateDimensions();
  }

  getData() {
    fetch('https://api.tasselpathway.com/diploma', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results,
            filteredDataSource: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onAddDiploma() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/diploma/', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        diploma_name: this.state.newDiplomaName,
        diploma_year: this.state.newDiplomaYear,
        total_credit: this.state.newTotalCredit
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isLoading: false,
          isNewOpen: false
        })

        this.getData();

      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          isNewOpen: false
        })
      });
  }

  onSaveDiploma() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/diploma/', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        diploma_id: this.state.selectedItem.diploma_id,
        diploma_name: this.state.selectedItem.diploma_name,
        diploma_year: this.state.selectedItem.diploma_year,
        total_credit: this.state.selectedItem.total_credit
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            isEditOpen: false
          })

          this.getData();
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          isEditOpen: false
        })
      });
  }

  onNewClick() {
    this.setState({
      isNewOpen: true
    })
  }

  onNewClose() {
    this.setState({
      isNewOpen: false
    })
  }

  onEditClick(event, item) {
    this.setState({
      isEditOpen: true,
      selectedItem: item
    })
  }

  onEditClose() {
    this.setState({
      isEditOpen: false
    })
  }

  handleChangePage(event, newPage) {
    this.setState({
      currentPage: newPage
    })
  }

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: event.target.value
    })
  }

  handleSort = (col) => {
    this.setState(state => ({
      sortBy: col,
      sortDirection: state.sortBy === col ? invertSort[state.sortDirection] : 'asc'
    }));
  }

  onRowClick(event, item) {

    this.setState({
      selectedCourse: item,
      isEditCourseOpen: false
    })
  }

  onDiplomaNameChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.diploma_name = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onDiplomaYearChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.diploma_year = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onTotalCreditChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.total_credit = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  render() {

    const { classes } = this.props;
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.filteredDataSource.length - this.state.currentPage * this.state.rowsPerPage);

    return (
      <div className={classes.root}>

        <Dialog open={this.state.isNewOpen}
          onClose={this.onNewClose} maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
            {i18n.t("ADMIN_NEW_DIPLOMA")}
          </DialogTitle>

          <DialogContent>

            <FormControl fullWidth style={{ marginTop: 10 }}>
              <InputLabel htmlFor="newDiplomaName">{i18n.t("ADMIN_TABLE_DIPLOMA_NAME")}</InputLabel>
              <Input variant="outline" fullWidth
                onChange={(event) => this.setState({ newDiplomaName: event.target.value })}
                id="newDiplomaName" aria-describedby="my-helper-text" />
            </FormControl>

            <FormControl fullWidth className={classes.formControl} style={{ marginTop: 10 }}>
              <InputLabel htmlFor="newDiplomaYear">{i18n.t("ADMIN_TABLE_DIPLOMA_YEAR")}</InputLabel>
              <Select
                value={this.state.newDiplomaYear}
                onChange={(event) => this.setState({ newDiplomaYear: event.target.value })}
                inputProps={{
                  name: 'newDiplomaYear',
                  id: 'newDiplomaYear',
                }}>
                <MenuItem disabled value={0}>{i18n.t("ADMIN_SELECT_YEAR")}</MenuItem>

                <MenuItem value={2019}>2019</MenuItem>
                <MenuItem value={2020}>2020</MenuItem>
                <MenuItem value={2021}>2021</MenuItem>
                <MenuItem value={2022}>2022</MenuItem>
                <MenuItem value={2023}>2023</MenuItem>
                <MenuItem value={2024}>2024</MenuItem>
                <MenuItem value={2025}>2025</MenuItem>
                <MenuItem value={2026}>2026</MenuItem>
                <MenuItem value={2027}>2027</MenuItem>
                <MenuItem value={2028}>2028</MenuItem>
                <MenuItem value={2029}>2029</MenuItem>
                <MenuItem value={2030}>2030</MenuItem>
                <MenuItem value={2031}>2031</MenuItem>
                <MenuItem value={2032}>2032</MenuItem>
                <MenuItem value={2033}>2033</MenuItem>
                <MenuItem value={2034}>2034</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth style={{ marginTop: 10 }}>
              <InputLabel htmlFor="newTotalCredit">{i18n.t("ADMIN_TABLE_TOTAL_CREDIT")}</InputLabel>
              <Input variant="outline" fullWidth
                onChange={(event) => this.setState({ newTotalCredit: event.target.value })}
                id="newTotalCredit" aria-describedby="my-helper-text" />
            </FormControl>

          </DialogContent>

          <DialogActions>
            <Button variant="contained" onClick={() => this.onAddDiploma()}
              style={{ color: '#FFF', marginRight: 20, borderRadius: 3, fontWeight: 'bold' }} color="primary">
              {i18n.t("ADMIN_ADD_DIPLOMA")}
            </Button>
          </DialogActions>

        </Dialog>

        <Dialog open={this.state.isEditOpen}
          onClose={this.onEditClose} maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onEditClose}>
            {i18n.t("ADMIN_EDIT_DIPLOMA")}
          </DialogTitle>

          <DialogContent>

            <FormControl fullWidth style={{ marginTop: 10 }}>
              <InputLabel htmlFor="editDiplomaName">{i18n.t("ADMIN_TABLE_DIPLOMA_NAME")}</InputLabel>
              <Input variant="outline" fullWidth
                value={this.state.selectedItem.diploma_name}
                onChange={(event) => this.onDiplomaNameChange(event)}
                id="editDiplomaName" aria-describedby="my-helper-text" />
            </FormControl>

            <FormControl fullWidth className={classes.formControl} style={{ marginTop: 10 }}>
              <InputLabel htmlFor="editDiplomaYear">{i18n.t("ADMIN_TABLE_DIPLOMA_YEAR")}</InputLabel>
              <Select
                value={this.state.selectedItem.diploma_year}
                onChange={(event) => this.onDiplomaYearChange(event)}
                inputProps={{
                  name: 'editDiplomaYear',
                  id: 'editDiplomaYear',
                }}>
                <MenuItem disabled value={0}>{i18n.t("ADMIN_SELECT_YEAR")}</MenuItem>

                <MenuItem value={2019}>2019</MenuItem>
                <MenuItem value={2020}>2020</MenuItem>
                <MenuItem value={2021}>2021</MenuItem>
                <MenuItem value={2022}>2022</MenuItem>
                <MenuItem value={2023}>2023</MenuItem>
                <MenuItem value={2024}>2024</MenuItem>
                <MenuItem value={2025}>2025</MenuItem>
                <MenuItem value={2026}>2026</MenuItem>
                <MenuItem value={2027}>2027</MenuItem>
                <MenuItem value={2028}>2028</MenuItem>
                <MenuItem value={2029}>2029</MenuItem>
                <MenuItem value={2030}>2030</MenuItem>
                <MenuItem value={2031}>2031</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth style={{ marginTop: 10 }}>
              <InputLabel htmlFor="newTotalCredit">{i18n.t("ADMIN_TABLE_TOTAL_CREDIT")}</InputLabel>
              <Input variant="outline" fullWidth
                value={this.state.selectedItem.total_credit}
                onChange={(event) => this.onTotalCreditChange(event)}
                id="newTotalCredit" aria-describedby="my-helper-text" />
            </FormControl>

          </DialogContent>

          <DialogActions>
            <Button variant="contained" onClick={() => this.onSaveDiploma()}
              style={{ color: '#FFF', marginRight: 20, borderRadius: 3, fontWeight: 'bold' }} color="primary">
              {i18n.t("ADMIN_SAVE_DIPLOMA")}
            </Button>
          </DialogActions>

        </Dialog>

        <div>

          <Grid container>
            <Grid item xs={2}>
              <Button variant="contained" color="primary" style={{ fontSize: 20, marginTop: 25, borderRadius: 3 }}
                onClick={() => this.onNewClick()}>
                <Typography style={{ fontSize: 14, color: '#FFF', fontWeight: 'bold' }}>{i18n.t("ADMIN_NEW_DIPLOMA")}</Typography>
              </Button>
            </Grid>
            <Grid item xs={6}>

            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              {this.props.selectedLanguage === "esp" ?
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    onChange={this.onSearch} style={{ width: '100%' }}
                    placeholder="Buscar"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </div>
                :
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    onChange={this.onSearch} style={{ width: '100%' }}
                    placeholder="Search"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </div>
              }
            </Grid>
          </Grid>

          <Paper style={{ borderRadius: 0, marginTop: 10 }}>

            <div className={classes.tableWrapper} style={{ maxHeight: this.state.height }}>
              <Table className={classes.table} style={{ height: this.state.height }}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_TABLE_DIPLOMA_NAME")}</Typography>
                      </div>
                    </TableCell>
                    <TableCell className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} >
                        <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_TABLE_DIPLOMA_YEAR")}</Typography>
                      </div>
                    </TableCell>
                    <TableCell className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} >
                        <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_TABLE_TOTAL_CREDIT")}</Typography>
                      </div>
                    </TableCell>
                    <TableCell className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_TABLE_DATE_MODIFIED")}</Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderBy(this.state.filteredDataSource, this.state.sortBy, this.state.sortDirection)
                    .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow key={index} classes={classes.tableRow} hover style={{ cursor: 'pointer' }}>
                          <TableCell onClick={(event) => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.diploma_name}</Typography></TableCell>
                          <TableCell onClick={(event) => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.diploma_year}</Typography></TableCell>
                          <TableCell onClick={(event) => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.total_credit}</Typography></TableCell>
                          <TableCell onClick={(event) => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{moment(row.date_modified).format('MM/DD/YYYY')}</Typography></TableCell>
                        </TableRow>
                      )
                    }
                    )}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 50 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[25]}
              component="div"
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.currentPage}
              count={this.state.filteredDataSource.length}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onPageChange={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    );
  }
}

DiplomaScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProgress: (progress) => { dispatch(setProgress(progress)); },
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DiplomaScreen)));