import GoogleIcon from '@mui/icons-material/Google';
import WarningIcon from '@mui/icons-material/Warning';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useGoogleLogin } from '@react-oauth/google';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Background from '../../assets/login.jpeg';
import i18n from '../../i18n.js';
import { login, loginFailure, loginSuccess } from '../../redux/actions/auth';
import { setLanguage } from '../../redux/actions/language';
import { setPerson } from '../../redux/actions/person';
import { setTask } from '../../redux/actions/task';
import { AzureLogin } from "./azureLogin";
import styles from './style.js';
import './styles.css';

function LoginScreen(props) {

  const [showPassword, setShowPassword] = useState('');
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [language, setLanguage] = useState('');

  const onGoogleLogin = useGoogleLogin({

    onSuccess: credentialResponse => {
      console.log(credentialResponse);

      fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${credentialResponse.access_token}`, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${credentialResponse.access_token}`,
        },
        credentials: 'omit'
      }).then(response => {
        return response.json()
      })
        .then((responseJson) => {

          console.log(responseJson.email)

          fetch('https://api.tasselconnect.com/authenticate_google', {
            method: "POST",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              user_name: responseJson.email
            }),
            credentials: 'omit'
          })
            .then(response => {
              return response.json()
            })
            .then((responseJson) => {

              console.log(responseJson);

              if (responseJson.authenticated === true) {

                props.onLoginSuccess(responseJson.user_name, '', responseJson.person_id, responseJson.grade_id,
                  responseJson.grade_name, responseJson.first_name, responseJson.last_name, responseJson.token,
                  responseJson.user_role_id, responseJson.user_right_id, responseJson.has_multi_district);

                props.setDistrict(responseJson.district_id, responseJson.district_name);

                props.onSetPerson(responseJson.person_person_id, responseJson.person_first_name,
                  responseJson.person_last_name);

                props.history.push('/');

              } else {
                props.onLoginFailure(true);
                props.history.push('/');
              }
            })
            .catch((e) => {

            });
        })
    }
  });

  const onAzureSuccess = (res) => {

    var user_name = res.account.username;

    fetch('https://api.tasselconnect.com/authenticate_google', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_name: user_name
      }),
      credentials: 'omit'
    })
      .then(response => {
        return response.json()
      })
      .then((responseJson) => {

        if (responseJson.authenticated === true) {

          props.onLoginSuccess(responseJson.user_name, '', responseJson.person_id, responseJson.grade_id,
            responseJson.grade_name, responseJson.first_name, responseJson.last_name, responseJson.token,
            responseJson.user_role_id, responseJson.user_right_id, responseJson.has_multi_district);

          props.setDistrict(responseJson.district_id, responseJson.district_name);

          props.onSetPerson(responseJson.person_person_id, responseJson.person_first_name,
            responseJson.person_last_name);

          props.history.push('/');

        } else {
          props.onLoginFailure(true);
          props.history.push('/');
        }
      })
      .catch((e) => {

      });

  }

  const onFailure = (res) => {
    console.log(res);
  }

  const userLogin = (e, click) => {
    if (e.charCode === 13 || click) {
      props.onLogin(username, password, props.selectedLanguage);
    }
  }

  const handleChangeLanguage = (event) => {
    let newlang = event.target.value;
    setLanguage(newlang);
    props.i18n.changeLanguage(newlang);
    props.onSetLanguage(newlang)
  };

  const { classes } = props;

  return (

    <Grid container>
      <Grid item xs={4} style={{ textAlign: 'center', backgroundColor: '#F7F7F7' }}>
        <img width="200" style={{ textAlign: 'center' }} src={require('../../assets/logo.png')} alt="Tassel" />
        <div className='loginShadow' style={{ padding: 20, marginLeft: 35, marginRight: 35, borderRadius: 4, backgroundColor: '#FFF' }}>

          <Typography style={{ color: '#2A2042', fontSize: 18, fontWeight: 'bold' }}>{i18n.t("LOGIN_MESSAGE")}</Typography>

          {props.hasError ?

            <Grid container style={{ backgroundColor: '#D55648', marginTop: 10, padding: 10, color: '#FFF', borderRadius: 4 }}>
              <Grid item xs={2}>
                <WarningIcon style={{ marginTop: 10 }} />
              </Grid>
              <Grid item xs={10}>
                <Typography style={{ fontWeight: 'bold', marginTop: 5, color: '#FFF', fontSize: 12, textAlign: 'start' }}>{i18n.t("LOGIN_ERROR")}<br />
                  {i18n.t("LOGIN_ERROR_TRY_AGAIN")} <Link style={{ color: "#FFF" }} to={"/forgot"}>{i18n.t("RESET_PASSWORD")}</Link></Typography>
              </Grid>
            </Grid>

            : null
          }

          <form style={{ marginTop: 20 }}>
            {props.selectedLanguage === "esp" ?
              <FormControl style={{ width: '100%', marginBottom: 10 }}>
                <TextField id="input-with-icon-adornment" label="E-mail"
                  style={{ width: '100%', fontSize: 12 }}
                  autoFocus
                  className={classes.textField}
                  error={props.hasError}
                  onChange={(evt) => setUserName(evt.target.value)} />
              </FormControl>
              :
              <FormControl style={{ width: '100%', marginBottom: 10 }}>
                <TextField id="input-with-icon-adornment" label="Email Address"
                  style={{ width: '100%', fontSize: 12 }}
                  autoFocus
                  className={classes.textField}
                  error={props.hasError}
                  onChange={(evt) => setUserName(evt.target.value)} />
              </FormControl>
            }
            {props.selectedLanguage === "esp" ?
              <FormControl style={{ width: '100%' }}>
                <TextField label="Contraseña"
                  style={{ width: '100%', fontSize: 12 }}
                  id="adornment-password"
                  error={props.hasError}
                  className={classes.textField}
                  type={showPassword ? 'text' : 'password'}
                  onChange={(evt) => setPassword(evt.target.value)}
                  onKeyPress={(e) => userLogin(e, false)} />
              </FormControl>
              :
              <FormControl style={{ width: '100%' }}>
                <TextField label="Password"
                  style={{ width: '100%', fontSize: 12 }}
                  id="adornment-password"
                  error={props.hasError}
                  className={classes.textField}
                  type={showPassword ? 'text' : 'password'}
                  onChange={(evt) => setPassword(evt.target.value)}
                  onKeyPress={(e) => userLogin(e, false)} />
              </FormControl>
            }

            <Grid container style={{ marginTop: 8 }}>
              <Grid item xs={12} style={{ textAlign: 'right' }}>
                <Link style={{ color: '#2A2042', fontSize: 12, fontWeight: 'bold', textAlign: 'center', textDecoration: 'none' }} to="/forgot" >
                  {i18n.t("LOGIN_TILE_FORGOT_PASSWORD")}
                </Link>
              </Grid>
            </Grid>

            <div style={{ marginTop: 5, width: '100%' }} >
              <FormControl style={{ width: '100%' }}>
                <InputLabel>{i18n.t("LOGIN_TILE_LANGUAGE")}</InputLabel>
                <Select
                  value={props.selectedLanguage}
                  onChange={handleChangeLanguage}
                  style={{ textAlign: 'left' }}>
                  <MenuItem style={{
                    fontFamily: 'Outfit',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    textTransform: 'none',
                    fontSize: '15px',
                    lineHeight: '20px'
                  }} value={'en'}>English</MenuItem>
                  <MenuItem style={{
                    fontFamily: 'Outfit',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    textTransform: 'none',
                    fontSize: '15px',
                    lineHeight: '20px'
                  }} value={'esp'}>Español</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div style={{ marginTop: 25, marginBottom: 20, textAlign: 'center' }} >
              <Button color="primary" style={{ width: 150 }} variant="contained" onClick={(e) => userLogin(e, true)}>
                <Typography style={{ fontWeight: 'bold', color: '#FFF', fontSize: 15 }}>{i18n.t("LOGIN_TILE_LOGIN_BUTTON")}</Typography>
              </Button>
            </div>

            <Typography style={{ color: '#2A2042', fontSize: 18, fontWeight: 'bold' }}>OR</Typography>

            <div style={{ marginTop: 15 }}>
              <Button fullWidth variant="outlined"
                startIcon={<GoogleIcon />}
                onClick={() => onGoogleLogin()}
                style={{ color: '#FFFFFF', backgroundColor: '#55BF96', borderRadius: '50px', height: 40 }}>
                <Typography style={{
                  color: '#FFFFFF', fontFamily: 'Outfit',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  textTransform: 'none',
                  fontSize: '15px',
                  lineHeight: '20px'
                }}>Google Login</Typography>
              </Button>

              <div style={{ marginTop: 15 }}></div>

              <AzureLogin
                onSuccess={(res) => onAzureSuccess(res)}
                onFailure={(res) => onFailure(res)}
                style={{ width: 250 }} />

            </div>

          </form>

        </div>

        <div style={{ padding: 20, marginLeft: 35, marginTop: 10, marginRight: 35, borderRadius: 4, backgroundColor: '#FFF' }}>

          <Typography style={{ color: '#2A2042', fontSize: 18, fontWeight: 'bold' }}>{i18n.t("REGISTER_TILE_REGISTER_NEW_ACCOUNT")}</Typography>

          <div style={{ marginTop: 40, textAlign: 'center' }} >
            <Button color="secondary" variant="contained"
              onClick={(e) => props.history.push('/register')}>
              <Typography style={{ fontWeight: 'bold', color: '#FFF', fontSize: 15 }}>{i18n.t("REGISTER_TILE_REGISTER_BUTTON")}</Typography>
            </Button>
          </div>

        </div>

      </Grid>

      <Grid item xs={8} style={{ backgroundColor: '#000' }}>
        <div style={{
          width: '100%', height: '100%', backgroundImage: `url(${Background})`,
          backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'
        }}>
          <div style={{ marginTop: 200, position: 'absolute', textAlign: 'center', width: '100%' }}>

          </div>
        </div>
      </Grid>
      <div className='ifScreenSmall'>
        <img width='25%' style={{ marginTop: 10, textAlign: 'center' }} src={require('../../assets/logo_white.png')} alt="Tassel" />
        <h1 style={{ textAlign: 'center', fontSize: 20, marginRight: '20%', marginLeft: '20%', color: 'white', letterSpacing: 1.2, fontWeight: 300 }}>Oops... Please expand your screen size.</h1>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img width="200" style={{ marginTop: 10 }} src={require('../../assets/devices.svg')} alt="Tassel" />
        </div>
        <h1 style={{ textAlign: 'center', fontSize: 20, marginRight: '20%', marginLeft: '20%', color: 'white', letterSpacing: 1.2, fontWeight: 300 }}>If you're trying to use <strong>Tassel</strong> on a phone or tablet, please <strong>download</strong> our <strong>mobile app.</strong></h1>
      </div>
    </Grid>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    language: state.language.selectedLanguage,
    hasError: state.auth.hasError,
    errorMessage: state.auth.errorMessage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (username, password, language) => { dispatch(login(username, password, language)); },
    onSetLanguage: (selectedLanguage) => { dispatch(setLanguage(selectedLanguage)); },
    onLoginFailure: () => { dispatch(loginFailure(true)); },
    onLoginSuccess: (userName, password, personId, gradeId, gradeName, firstName, lastName, token,
      userRoleId, userRightId, hasMultiDistrict) => {
      dispatch(loginSuccess(userName, password, personId,
        gradeId, gradeName, firstName, lastName, token, userRoleId, userRightId, hasMultiDistrict));
    },
    onSetTask: (taskId, taskName, taskDescription, estimatedTime, totalPoints, autoGrade, answerText, taskUpdated) => { dispatch(setTask(taskId, taskName, taskDescription, estimatedTime, totalPoints, autoGrade, answerText, taskUpdated)); },
    onSetPerson: (personId, firstName, lastName) => { dispatch(setPerson(personId, firstName, lastName)); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(LoginScreen))));