import { faFile, faFilePdf, faFileWord, faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import FolderIcon from '@mui/icons-material/Folder';
import {
  Button,
  Card, CardContent,
  Dialog, DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  List, ListItem, ListItemIcon, ListItemText,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import { Document, PDFViewer, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import { setIsEditingResume, setSelectedResumeTemplate } from '../../../redux/actions/resume_builder';
import styles from './style';
import './styles.css';

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


var AWS = require('aws-sdk')

var S3 = new AWS.S3({
  accessKeyId: 'AKIARE5YHMQIWMYAOF6S',
  secretAccessKey: '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',
  region: 'us-east-1'
});

class ResumeScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isUploadOpen: false,
      hasResume: false,
      dateCreated: '',
      resumeBase64: '',
      resumeId: '',
      savingChanges: false,
      isBadFileTypeOpen: false,
      isResumeBuilderOpen: false,
      isPrintResumeOpen: false,
      dataSource: [],
      dataSource2: [],
      selectedResume: {},
      resume_objective: {},
      resume_skills: [],
      resume_work_experience: [],
      resume_work_based_learning: [],
      resume_service_learning: [],
      resume_achievements: []

    }

    this.onUploadClose = this.onUploadClose.bind(this);
  }

  componentDidMount() {
    this.getData(this.props.profileId);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.profileId);
  }

  getData(profileId) {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_resume/' + profileId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          if (responseJson.results.length > 0) {
            this.setState({
              isLoading: false,
              hasResume: true,
              dataSource2: responseJson.results
            })
          } else {
            this.setState({
              isLoading: false,
              dataSource2: [],
              hasResume: false,
            })
          }
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/resume/' + profileId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then(responseJson => {

        if (responseJson.error === false) {

          this.setState({
            isLoading: false,
            dataSource: responseJson.rows,
            hasResume: true
          })
        }
      })

  }

  getResumeObjective = (resumeId) => {

    fetch("https://api.tasselpathway.com/resume/resume_objective/" + resumeId, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit",
    })
      .then(res => res.json())
      .then(responseJson => {

        this.setState({
          resume_objective: responseJson.rows[0]
        })
      })
  }

  getResumeSkills = (resumeId) => {

    fetch("https://api.tasselpathway.com/resume/resume_skills/" + resumeId, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit",
    })
      .then(res => res.json())
      .then(responseJson => {
        this.setState({
          resume_skills: responseJson.rows
        })
      })
  }

  getResumeWorkExperience = (resumeId) => {

    fetch("https://api.tasselpathway.com/resume/resume_work_experience/" + resumeId, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit",
    })
      .then(res => res.json())
      .then(responseJson => {
        this.setState({
          resume_work_experience: responseJson.rows
        })
      })
  }

  getResumeAchievements = (resumeId) => {
    fetch("https://api.tasselpathway.com/resume/resume_achievements/" + resumeId, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit"
    })
      .then(res => res.json())
      .then(responseJson => {

        this.setState({
          resume_achievements: responseJson.rows
        })
      })
  }

  getResumeWorkBasedLearning = (resumeId) => {

    fetch("https://api.tasselpathway.com/resume/resume_work_based_learning/" + resumeId, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit"
    })
      .then(res => res.json())
      .then(responseJson => {
        this.setState({
          resume_work_based_learning: responseJson.rows
        })
      })
  }

  getResumeServiceLearning = (resumeId) => {

    fetch("https://api.tasselpathway.com/resume/resume_service_learning/" + resumeId, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit"
    })
      .then(res => res.json())
      .then(responseJson => {

        this.setState({
          resume_service_learning: responseJson.rows
        })
      })
  }

  removeResume = (item) => {

    this.setState({
      isLoading: true,
      resumeBase64: ''
    })

    fetch("https://api.tasselpathway.com/profile_resume", {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit",
      body: JSON.stringify({
        resume_id: item.resume_id
      })
    })
      .then(responseJson => {

        this.getData(this.props.profileId);

      })
      .catch((error) => {

      });
  }

  onUploadClick() {
    this.setState({
      isUploadOpen: true
    })
  }

  openResume(item) {

    window.open(item.image_url, "_blank");
  }

  badFileTypeClose = () => {
    this.setState({
      isBadFileTypeOpen: false
    })
  }

  onResumeClose = () => {
    this.setState({
      isResumeOpen: false
    })
  }

  onUploadClose() {
    this.setState({
      isUploadOpen: false
    })
  }

  async uploadAWS(params) {
    await S3.upload(params, function (err, data, token) {
      return ''
    })
  }

  async onUploadResumeAWS(base64, filePath) {
    return Promise.all([this.onSaveResume(base64, filePath)])
  }

  async onSaveResume(base64, filePath) {

    var url = this.state.resumeBase64;

    const response = await fetch(url)
      .then(res => res.blob())
      .then(blob => {
        const base64Data = new Buffer.from(base64.replace(/^data:image\/\w+;base64,/, ""), 'base64')

        let params = {
          Key: filePath,
          Body: base64Data,
          ContentType: 'image/jpeg',
          Bucket: 'tassel.com',
          ContentEncoding: 'base64'
        }

        this.uploadAWS(params);

        return 'image/jpeg';
      })

    return response;
  }

  onDrop = (file) => {
    this.setState({
      fileType: file[0].path.split('.')[1]
    })

    if (file[0].path.split('.')[1] === 'png' || file[0].path.split('.')[1] === 'jpeg'
      || file[0].path.split('.')[1] === 'jpg' || file[0].path.split('.')[1] === 'pdf'
      || file[0].path.split('.')[1] === 'docx' || file[0].path.split('.')[1] === 'doc') {
      this.setState({
        savingChanges: true,
      })

      var props = this.props;
      var scope = this;
      var ext = file[0].path.split('.')[1];
      var random = Math.floor(Math.random() * 900000000000000000);
      var dateCreated = new Date();

      var filename = 'pathway/resume/' + random + '.' + ext;

      var params = {
        Key: filename,
        Body: file[0],
        ContentType: file[0].type,
        Bucket: 'tassel.com'
      };

      S3.upload(params, function (err, data) {
        if (err) {
          console.log(err, err.stack);
        }
        else {
          fetch('https://api.tasselpathway.com/profile_resume/resumeImage', {
            method: "POST",
            headers: {
              "Accept": "application/json",
              "Content-Type": 'application/json',
              "token": props.token
            },
            credentials: "omit",
            body: JSON.stringify({
              aws_bucket: "tassel.com",
              aws_key: filename,
              resume_content_type: file[0].type,
              person_id: props.profileId,
              date_created: dateCreated
            })
          })

            .then(res => res.json())
            .then((responseJson) => {

              setTimeout(function () {
                this.setState({ savingChanges: false });
              }.bind(this), 7000);  // wait 7 seconds, then reset to false
              setTimeout(
                function () {
                  this.getData(this.props.profileId
                  )
                }.bind(this), 5000)
            })
        }
      }.bind(this))

      this.setState({
        isUploadOpen: false
      })
    }

    else {
      this.setState({
        isBadFileTypeOpen: true
      })
    }
  }

  viewResume() {

    if (this.state.selectedResume.template === "modern") {
      return (
        <Document>
          <Page size="A4" >
            <View style={{
              backgroundColor: '#25D192', height: 100, width: '98%',
              marginLeft: 5, marginRight: 5, marginTop: 5, marginBottom: 5
            }}>
              <View style={{ marginTop: 20, textAlign: 'center' }}>
                <Text style={{ color: '#fff', fontSize: 40 }}>{this.props.firstName + ' ' + this.props.props.lastName}</Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: 'row' }}>
              <View style={{ float: 'left', width: '45%', marginTop: 30, marginLeft: 10 }}>
                <View>
                  <Text style={{ marginBottom: 10 }}>Objective</Text>
                  <View style={{ borderBottom: 10, borderBottomStyle: 'solid', borderBottomColor: '#25D192' }}></View>
                  <Text style={{ fontSize: 12, marginTop: 10 }}>{this.state.resume_objective.objective_text}</Text>
                </View>
                {
                  this.state.resume_skills.length > 0 ?
                    <View style={{ marginTop: 50 }}>
                      <Text style={{ marginBottom: 10 }}>Skills</Text>
                      <View style={{ borderBottom: 10, borderBottomStyle: 'solid', borderBottomColor: '#25D192', marginBottom: 10 }}>

                      </View>
                      {this.state.resume_skills.map((item, key) => {
                        return (
                          <Text style={{ paddinBottom: 2, fontSize: 12, marginBottom: 5, }}> - {item.skill_name}</Text>

                        )
                      })}
                    </View>
                    :
                    null
                }
                <View style={{ marginTop: 50 }}>
                  <Text style={{ marginBottom: 10 }}>Education</Text>
                  <View style={{ borderBottom: 10, borderBottomStyle: 'solid', borderBottomColor: '#25D192' }}></View>
                  <Text style={{ marginTop: 10, fontSize: 12 }}>{this.props.schoolName}</Text>
                </View>
              </View>

              <View style={{ float: 'right', width: '45%', marginLeft: 40, marginTop: -20 }}>
                {
                  this.state.resume_work_experience.length > 0 ?
                    <View style={{ marginTop: 50 }}>
                      <Text style={{ marginBottom: 10 }}>Work Experience</Text>
                      <View style={{ borderBottom: 10, borderBottomStyle: 'solid', borderBottomColor: '#25D192' }}>

                      </View>
                      {this.state.resume_work_experience.map((item, key) => {
                        return (
                          // <Text style={{paddinBottom:2,fontSize:12}}> - {item.skill_name}</Text>
                          <View>
                            <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{item.place_of_work} </Text>
                            <Text style={{ fontSize: 10 }}>{item.position} {moment(item.start_date).format("MM/DD/YYYY")}-{moment(item.end_date).format("MM/DD/YYYY")}</Text>
                          </View>
                        )
                      })}
                    </View>
                    :
                    null
                }

                {
                  this.state.resume_service_learning.length > 0 ?
                    <View style={{ marginTop: 50 }}>
                      <Text style={{ marginBottom: 10 }}>Volunteer Experience</Text>
                      <View style={{ borderBottom: 10, borderBottomStyle: 'solid', borderBottomColor: '#25D192' }}>

                      </View>
                      {this.state.resume_service_learning.map((item, key) => {
                        return (
                          <View style={{ marginTop: 10, marginBottom: 10 }}>
                            <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{item.service_location} </Text>
                            <Text style={{ fontSize: 10 }}>{item.service_description}</Text>
                          </View>
                        )
                      })}
                    </View>
                    :
                    null
                }
                {
                  this.state.resume_achievements.length > 0 ?
                    <View style={{ marginTop: 50 }}>
                      <Text style={{ marginBottom: 10 }}>Achievements</Text>
                      <View style={{ borderBottom: 10, borderBottomStyle: 'solid', borderBottomColor: '#25D192' }}>

                      </View>
                      {this.state.resume_achievements.map((item, key) => {
                        return (
                          <View style={{ marginBottom: 10, marginTop: 10 }}>
                            <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{item.achievement_name} </Text>
                            <Text style={{ fontSize: 10 }}>{item.achievement_description}</Text>
                          </View>
                        )
                      })}
                    </View>
                    :
                    null
                }

              </View>
            </View>
          </Page>
        </Document>
      )

    } else {

      return (


        <Document>
          <Page size="A4" >
            <View style={{ backgroundColor: '#25D192', height: 5, width: '100%' }}>
              <View style={{ marginTop: 25, marginLeft: 10 }}>
                <Text style={{ color: '#000', fontSize: 40 }}>{this.props.firstName + ' ' + this.props.props.lastName}</Text>

              </View>
            </View>
            <View style={{ marginTop: 100 }}>
              <View style={{}}>
                <View style={{ marginLeft: 10, marginRight: 10 }}>
                  <Text style={{ marginBottom: 10 }}>Objective</Text>
                  <View style={{ borderBottom: 10, borderBottomStyle: 'solid', borderBottomColor: '#25D192' }}></View>
                  <Text style={{ fontSize: 12, marginTop: 10 }}>{this.state.resume_objective.objective_text}</Text>
                </View>

                {
                  this.state.resume_skills.length > 0 ?
                    <View style={{ marginTop: 15, marginLeft: 10, marginRight: 10 }}>
                      <Text style={{ marginBottom: 10 }}>Skills</Text>
                      <View style={{ borderBottom: 10, borderBottomStyle: 'solid', borderBottomColor: '#25D192', marginBottom: 10 }}>

                      </View>
                      {this.state.resume_skills.map((item, key) => {
                        return (
                          <Text style={{ paddingBottom: 2, fontSize: 12, marginBottom: 5, }}> - {item.skill_name}</Text>

                        )
                      })}
                    </View>
                    :
                    null
                }


                {
                  this.state.resume_work_experience.length > 0 ?
                    <View style={{ marginTop: 15, marginLeft: 10, marginRight: 10 }}>
                      <Text style={{ marginBottom: 10 }}>Work Experience</Text>
                      <View style={{ borderBottom: 10, borderBottomStyle: 'solid', borderBottomColor: '#25D192' }}>

                      </View>
                      {this.state.resume_work_experience.map((item, key) => {
                        return (
                          // <Text style={{paddinBottom:2,fontSize:12}}> - {item.skill_name}</Text>
                          <View>
                            <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{item.place_of_work} </Text>
                            <Text style={{ fontSize: 10 }}>{item.position} {moment(item.start_date).format("MM/DD/YYYY")}-{moment(item.end_date).format("MM/DD/YYYY")}</Text>
                          </View>
                        )
                      })}
                    </View>
                    :
                    null
                }

                {
                  this.state.resume_service_learning.length > 0 ?
                    <View style={{ marginTop: 15, marginLeft: 10, marginRight: 10 }}>
                      <Text style={{ marginBottom: 10 }}>Volunteer Experience</Text>
                      <View style={{ borderBottom: 10, borderBottomStyle: 'solid', borderBottomColor: '#25D192' }}>

                      </View>
                      {this.state.resume_service_learning.map((item, key) => {
                        return (
                          <View style={{ marginTop: 10, marginBottom: 10 }}>
                            <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{item.service_location} </Text>
                            <Text style={{ fontSize: 10 }}>{item.service_description}</Text>
                          </View>
                        )
                      })}
                    </View>
                    :
                    null
                }
                {
                  this.state.resume_achievements.length > 0 ?
                    <View style={{ marginTop: 15, marginLeft: 10, marginRight: 10 }}>
                      <Text style={{ marginBottom: 10 }}>Achievements</Text>
                      <View style={{ borderBottom: 10, borderBottomStyle: 'solid', borderBottomColor: '#25D192' }}>

                      </View>
                      {this.state.resume_achievements.map((item, key) => {
                        return (
                          <View style={{ marginBottom: 10, marginTop: 10 }}>
                            <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{item.achievement_name} </Text>
                            <Text style={{ fontSize: 10 }}>{item.achievement_description}</Text>
                          </View>
                        )
                      })}
                    </View>
                    :
                    null
                }
                <View style={{ marginTop: 15, marginLeft: 10, marginRight: 10 }}>
                  <Text style={{ marginBottom: 10 }}>Education</Text>
                  <View style={{ borderBottom: 10, borderBottomStyle: 'solid', borderBottomColor: '#25D192' }}></View>
                  <Text style={{ marginTop: 10, fontSize: 12 }}>{this.props.schoolName}</Text>
                </View>

              </View>
            </View>
          </Page>
        </Document>
      )
    }
  }

  openResumeBuilder = () => {
    this.setState({
      isResumeBuilderOpen: true
    })
  }

  navigateToEditResumeBuilder = (item) => {

    this.props.onSetResumeTemplate(item.template)
    this.props.onSetIsEditingResume(true, item.resume_id)
    this.props.history.push('/resume_builder_objectives')
  }

  navigateToResumeBuilder = (templateName) => {
    this.props.onSetResumeTemplate(templateName)
    this.props.history.push('/resume_builder_objectives')
  }

  openBuiltResume = (item) => {

    if (item.objective !== 0) {
      this.getResumeObjective(item.resume_id);
    }
    if (item.work_experience !== 0) {
      this.getResumeWorkExperience(item.resume_id)
    }
    if (item.service_learning !== 0) {
      this.getResumeServiceLearning(item.resume_id)
    }
    if (item.honors_and_awards !== 0) {
      this.getResumeAchievements(item.resume_id)
    }
    if (item.skills !== 0) {
      this.getResumeSkills(item.resume_id)
    }
    if (item.work_based_learning !== 0) {
      this.getResumeWorkBasedLearning(item.resume_id)
    }


    this.setState({
      selectedResume: item,
      isPrintResumeOpen: true
    })
  }
  handleClosePrintResume = () => {
    this.setState({
      selectedResume: {},
      isPrintResumeOpen: false
    })
  }

  renderList() {
    return (
      <List style={{ width: '100%', height: 50 }}>
        <ListItem>
          <ListItemIcon>
            <FolderIcon />
          </ListItemIcon>
          <ListItemText
            primary="Single-line item"
          />
        </ListItem>
      </List>
    )
  }
  removeResumeData = (resumeId) => {

    fetch("https://api.tasselpathway.com/resume/resume_objective/delete/" + resumeId, {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit"
    })
      .then(res => res.json())
      .then(responseJson => {

      })

    fetch("https://api.tasselpathway.com/resume/resume_skills/delete/" + resumeId, {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit"
    })
      .then(res => res.json())
      .then(responseJson => {


      })

    fetch("https://api.tasselpathway.com/resume/resume_work_experience/delete/" + resumeId, {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit"
    })
      .then(res => res.json())
      .then(responseJson => {

      })

    fetch("https://api.tasselpathway.com/resume/resume_achievements/delete/" + resumeId, {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit"
    })
      .then(res => res.json())
      .then(responseJson => {


      })

    fetch("https://api.tasselpathway.com/resume/resume_work_based_learning/delete/" + resumeId, {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit"
    })
      .then(res => res.json())
      .then(responseJson => {

      })
  }

  removeBuiltResume = (resumeId) => {

    this.removeResumeData(resumeId)

    fetch("https://api.tasselpathway.com/resume/" + resumeId, {
      method: "DELETE",
      headers: {
        "accept": "application/json",
        "content-type": "application/json",
        "token": this.props.token
      }
    })
      .then(res => res.json())
      .then(responseJson => {

        if (responseJson.error === false) {
          this.getData(this.props.profileId);

        }
      })
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>

        <Dialog
          fullScreen
          open={this.state.isPrintResumeOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle onClose={this.handleClosePrintResume} id="alert-dialog-title"
            titleStyle={{ textAlign: "center", marginBottom: 10 }}>Print or download profile below.</DialogTitle>
          <PDFViewer style={{ minHeight: '100%' }}>
            {this.viewResume()}
          </PDFViewer>
        </Dialog>
        <Dialog
          open={this.state.isResumeBuilderOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth='md'
          fullWidth={true}>
          <DialogTitle id="alert-dialog-title" titleStyle={{ textAlign: "center" }}
            onClose={() => this.setState({ isResumeBuilderOpen: false })}>New Resume (Select your resume template)</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid container style={{ marginLeft: 65 }}>
                <Grid item xs={6}>
                  <div style={{ borderWidth: 1, borderStyle: 'solid', borderColor: 'grey', width: 352 }}>
                    <img src={require("../../../assets/modernResumeTemplate.png")} onClick={() => this.navigateToResumeBuilder('modern')}
                      alt="Modern" style={{ height: 450, width: 350, cursor: 'pointer' }} />
                  </div>
                  <Button style={{ marginTop: 10, width: 352 }} variant={'contained'} color={'primary'} onClick={() => this.navigateToResumeBuilder('modern')}>
                    <Typography style={{ color: "#fff" }}>
                      Choose Modern Template
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ borderWidth: 1, borderStyle: 'solid', borderColor: 'grey', width: 352 }}>
                    <img src={require("../../../assets/traditionalResume.png")} onClick={() => this.navigateToResumeBuilder("traditional")}
                      alt="Traditional" style={{ height: 450, width: 350, cursor: 'pointer' }} />
                  </div>
                  <Button style={{ marginTop: 10, width: 352 }} variant={'contained'} color={'primary'} onClick={() => this.navigateToResumeBuilder("traditional")}>
                    <Typography style={{ color: "#fff" }}>
                      Choose Traditional Template
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.savingChanges}
          onClose={this.savingChangesClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth='sm'
          fullWidth={true}>
          <DialogTitle id="alert-dialog-title" titleStyle={{ textAlign: "center" }}>{i18n.t("RESUME_SAVING")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{ display: 'flex', justifyContent: 'space-around', padding: 20 }}>
                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                  <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.isUploadOpen}
          onClose={this.onUploadClose} maxWidth="sm"
          aria-labelledby="form-dialog-title">
          <DialogTitle style={{ fontSize: 20 }} id="customized-dialog-title"
            onClose={this.onUploadClose}>
            {i18n.t("NEW_RESUME")}
          </DialogTitle>

          <DialogContent>
            <Grid container>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button variant="contained" color="primary" onClick={() => this.openResumeBuilder()} >
                  <Typography style={{ color: '#FFF' }}>Build New Resume</Typography>
                </Button>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20, textAlign: 'center' }}>
                <Typography style={{ fontSize: 18 }}>Or Upload Existing Resume</Typography>
              </Grid>

              <Grid item xs={12} style={{ marginLeft: 75, marginBottom: 20, textAlign: 'center' }}>
                <Dropzone
                  noKeyboard
                  ref={this.dropzoneRef}
                  onDrop={this.onDrop}>
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <FontAwesomeIcon style={{ fontSize: 56, color: '#33CB89' }} icon={faUpload} />
                      <p style={{ fontSize: 12 }}>{i18n.t("RESUME_CLICK")}</p>
                    </div>
                  )}
                </Dropzone>
              </Grid>
            </Grid>
          </DialogContent>

        </Dialog>

        <Dialog
          open={this.state.isBadFileTypeOpen}
          onClose={this.badFileTypeClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth='sm'
          fullWidth={true}>
          <DialogContent>
            <Typography style={{ textAlign: 'center', fontSize: 20, paddingBottom: 15 }}>Oops, looks like you tried to upload a "{this.state.fileType}" file.</Typography>
            <DialogContentText id="alert-dialog-description">
              <Typography style={{ textAlign: 'center', fontSize: 16 }}>Please try again with a png, jpeg, jpg, or pdf file.</Typography>
              <Button variant="contained" style={{ marginTop: 20, float: 'right', backgroundColor: '#EA605B' }} onClick={() => this.badFileTypeClose()}>
                <Typography style={{ fontWeight: 'bold', fontSize: 16, color: '#FFF' }}>Close</Typography>
              </Button>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Grid container spacing={12}>

          <Grid item xs={12}>

            <Card style={{ width: '100%', height: 300, borderRadius: 3 }}>

              <CardContent>

                <Grid container style={{ borderBottom: '1px solid #F0F0F0' }}>
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>
                      {i18n.t("RESUME")}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                    {this.props.personId === this.props.profileId ?
                      <IconButton style={{ marginTop: 10 }} onClick={() => this.setState({ isUploadOpen: true })}>
                        <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPlus} />
                      </IconButton>
                      : null}
                  </Grid>
                </Grid>

                <Grid container>

                  <Grid item xs={12}>

                    <Grid container spacing={8} style={{ height: 175, width: '100%', overflow: 'auto' }}>

                      {this.state.dataSource.map((item, key) => {
                        return (
                          <Grid key={key} item xs={6} style={{
                            textAlign: 'center', width: '100%',
                            alignItems: 'center', cursor: 'pointer'
                          }}>

                            <FontAwesomeIcon onClick={() => this.openBuiltResume(item)}
                              style={{ color: '#25D192', textAlign: 'center', fontSize: 56 }} icon={faFile} />

                            <Typography style={{ marginTop: 5, textAlign: 'center', fontSize: 12 }}>{moment(item.date_created).format("MM/DD/YYYY")}</Typography>

                            <Button size="small" color="primary" onClick={() => this.navigateToEditResumeBuilder(item)}
                              style={{ marginTop: 10, color: '#FFF' }} variant='contained'>Edit</Button>

                            <Button size="small" color="primary" onClick={() => this.removeBuiltResume(item.resume_id)}
                              style={{ marginTop: 10, color: '#FFF' }} variant='contained'>Remove</Button>

                          </Grid>
                        )
                      })}

                      {this.state.dataSource2.map((item, key) => {

                        return (

                          <Grid key={key} item xs={6} style={{
                            textAlign: 'center', width: '100%',
                            alignItems: 'center', cursor: 'pointer'
                          }}>

                            {item.resume_content_type === 'application/pdf' ?

                              <FontAwesomeIcon onClick={() => this.openResume(item)}
                                style={{ color: '#25D192', textAlign: 'center', fontSize: 56 }} icon={faFilePdf} />

                              : null}

                            {item.resume_content_type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ?

                              <FontAwesomeIcon onClick={() => this.openResume(item)}
                                style={{ color: '#25D192', textAlign: 'center', fontSize: 56 }} icon={faFileWord} />

                              : null}


                            <Typography style={{ marginTop: 5, textAlign: 'center', fontSize: 12 }}>{moment(item.date_created).format("MM/DD/YYYY")}</Typography>

                            <Button size="small" color="primary" onClick={() => this.removeResume(item)}
                              style={{ marginTop: 10, color: '#FFF' }} variant='contained'>Remove</Button>

                          </Grid>
                        )
                      })}

                    </Grid>

                  </Grid>

                </Grid>
              </CardContent>
            </Card>
          </Grid>

        </Grid>

      </div>
    );
  }
}

ResumeScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    personId: state.auth.personId,
    profileId: state.profile.profileId,
    gradeId: state.profile.gradeId,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    firstName: state.profile.firstName,
    lastName: state.profile.lastName,
    schoolName: state.profile.schoolName
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSetIsEditingResume: (val, resumeId) => { dispatch(setIsEditingResume(val, resumeId)) },
    onSetResumeTemplate: (templateName) => { dispatch(setSelectedResumeTemplate(templateName)) },
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ResumeScreen)));