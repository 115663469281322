import { faCamera, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Avatar, Button, Card, CardActions, CardContent,
  CardHeader, Chip, Dialog, DialogActions, DialogContent, FormControl, Grid,
  IconButton,
  ImageList,
  Menu, MenuItem, Select, Tab, Tabs, TextField, Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Resizer from 'react-image-file-resizer';
import Linkify from 'react-linkify';
import { connect } from 'react-redux';
import NoData from '../../assets/noData.svg';
import PostDemo from '../../assets/tasselShareNews.mov';
import i18n from '../../i18n';
import { logout } from '../../redux/actions/auth';
import ILPProgressScreen from '../shared/ilpprogress/index';
import DistrictLeaderboard from './districtleaderboard/index';
import LinksScreen from './linksSection/index';
import LinksTeacherScreen from './linksSectionTeacher';
import StudentLeaderboard from './studentleadrboard/index';
import styles from './style';
var AWS = require('aws-sdk');

var s3 = new AWS.S3({
  accessKeyId: 'AKIARE5YHMQIWMYAOF6S',
  secretAccessKey: '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',
  region: 'us-east-1'
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class DashboardScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      onDeleteOpen: false,
      isEditOpen: false,
      badges: [],
      grade_options: [],
      pathways: [],
      newsFeedData: [],
      teacherNewFeedData: [],
      student_leaders: [],
      district_leaders: [],
      totalPoints: 0,
      totalProjects: 0,
      totalActivities: 0,
      totalServiceHours: 0,
      logoSrc: null,
      progress: 0,
      serviceProgress: 50,
      message: '',
      feedType: 0,
      targetAudience: 'Target Audience',
      selectedItem: {},
      specifiedTargetAudience: 0,
      specifiedPostViewers: [],
      isOpen: false,
      title: '',
      url: '',
      urlDomain: '',
      urlTitle: '',
      urlDescription: '',
      postToDelete: 0,
      reaction: 1,
      schoolId: 0,
      schoolName: '',
      TabValue: 0,
      isAudienceOpen: false,
      postDemoOpen: false

    }
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
    this.onDeleteOpen = this.onDeleteOpen.bind(this);
    this.onDeleteClose = this.onDeleteClose.bind(this);
    this.onEditOpen = this.onEditOpen.bind(this);
    this.onEditClose = this.onEditClose.bind(this);
    this.onAudienceOpen = this.onAudienceOpen.bind(this);
    this.onAudienceClose = this.onAudienceClose.bind(this);
    this.onAudienceCloseModal = this.onAudienceCloseModal.bind(this);
    this.onPostDemoOpen = this.onPostDemoOpen.bind(this);
    this.onPostDemoClose = this.onPostDemoClose.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.getData(this.state.schoolId);
  }
  componentDidMount() {
    fetch('https://api.tasselpathway.com/persons/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.getFeed(responseJson.results[0].schools[0].school_id)
          this.getStudentLeaderBoardData(responseJson.results[0].schools[0].school_id)
          this.getData(responseJson.results[0].schools[0].school_id)
          this.setState({
            isLoading: false,
            firstName: responseJson.results[0].first_name,
            lastName: responseJson.results[0].last_name,
            gradeId: responseJson.results[0].grade_id,
            gradeName: responseJson.results[0].grade_name,
            districtId: responseJson.results[0].district_id,
            districtName: responseJson.results[0].district_name,
            schoolId: responseJson.results[0].schools[0].school_id,
            schoolName: responseJson.results[0].schools[0].school_name,
            bannerImageUrl: responseJson.results[0].banner_image_url,
            logoImageUrl: responseJson.results[0].logo_image_url
          })
        } else if (responseJson.authenticated === false) {
          this.props.onLogout();
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      })

    fetch('https://api.tasselpathway.com/profile_badge/person/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            badges: responseJson.results
          })
        }

      })
      .catch((error) => {

        this.setState({
          isLoading: false
        })

      });

    fetch("https://api.tasselpathway.com/grades", {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content_Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit"
    })
      .then(res => res.json())
      .then(responseJson => {
        this.setState({
          grade_options: responseJson.results
        })
      })

    fetch('https://api.tasselpathway.com/pathway', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            pathways: responseJson.results
          })
        }
      })

    fetch("https://api.tasselpathway.com/scores/" + this.props.personId, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": 'application/json',
        "token": this.props.token
      },
      credentials: "omit"
    })
      .then(res => res.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            totalPoints: responseJson.results[0].total_points,
            totalActivities: responseJson.results[0].total_activities,
            totalServiceHours: responseJson.results[0].total_service_hours,
            totalProjects: responseJson.results[0].total_projects
          })
        }
      })
      .catch((error) => {

      })
    fetch("https://api.tasselpathway.com/scores", {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": 'application/json',
        "token": this.props.token
      },
      credentials: "omit"
    })
      .then(res => res.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            district_leaders: responseJson.results
          })
        }
      })
  }

  getStudentLeaderBoardData(schoolId) {

    fetch("https://api.tasselpathway.com/scores/school/" + schoolId, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": 'application/json',
        "token": this.props.token
      },
      credentials: "omit"
    })
      .then(res => res.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            student_leaders: responseJson.results
          })
        }
      })
  }

  getFeed(schoolId) {
    fetch("https://api.tasselpathway.com/profile_feed/" + schoolId, {
      method: "GET",
      headers: {
        "Accept": 'application/json',
        "Content-Type": 'application/json',
        "token": this.props.token
      },
      credentials: 'omit'
    })
      .then(res => res.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          let currentData = responseJson.results
          this.setState({
            newsFeedData: currentData
          })
        }
      })
      .catch((error) => {

      })
  }

  getData(schoolId) {
    fetch("https://api.tasselpathway.com/feed/teacher/" + schoolId, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": 'application/json',
        "token": this.props.token
      },
      credentials: "omit"
    })
      .then(res => res.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            teacherNewFeedData: responseJson.results
          })
        }
      })
      .catch((error) => {

      })
  }

  handleMessageTextChange(e) {
    var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    var regex = new RegExp(expression);
    var t = e.target.value;

    if (t.match(regex)) {
      this.setState({
        message: e.target.value,
        feedType: 1
      })
    } else {
      this.setState({
        message: e.target.value,
        feedType: 4
      })
    }
  }

  handleAudienceChange(e) {
    this.setState({
      targetAudience: e.target.value
    })
    if (e.target.value === "Everyone") {

      fetch("https://api.tasselpathway.com/persons/district", {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "token": this.props.token
        },
        credentials: "omit"
      })
        .then(res => res.json())
        .then(responseJson => {
          this.setState({
            specifiedTargetAudience: 0,
            specifiedPostViewers: responseJson.results
          })
        })
    }

  }
  handleSelectedAudience(e) {
    let classId = e.target.value
    fetch("https://api.tasselpathway.com/persons/class/" + classId, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit"
    })
      .then(res => res.json())
      .then(responseJson => {
        this.setState({
          specifiedTargetAudience: e.target.value,
          specifiedPostViewers: responseJson.results
        })
      })

  }
  handleSelectedPathway(e) {
    let pathwayId = e.target.value
    fetch("https://api.tasselpathway.com/persons/pathway/" + pathwayId, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit"
    })
      .then(res => res.json())
      .then(responseJson => {
        this.setState({
          specifiedTargetAudience: e.target.value,
          specifiedPostViewers: responseJson.results
        })
      })
  }

  renderGradeList() {
    return (
      <Select
        style={{ width: '97%' }}
        value={this.state.specifiedTargetAudience}
        onChange={(e) => this.handleSelectedAudience(e)}
        name="age"
        displayEmpty
      >
        <MenuItem value={0} disables>Select Grade</MenuItem>
        {this.state.grade_options.map((item, key) => {
          return (
            <MenuItem key={key} value={item.grade_id}>{item.grade_name} Class</MenuItem>
          )
        })}
      </Select>
    )
  }

  renderPathwayList() {
    return (
      <Select
        style={{ width: '97%' }}
        value={this.state.specifiedTargetAudience}
        onChange={(e) => this.handleSelectedPathway(e)}
        name="age"
        displayEmpty
      >
        <MenuItem value={0} disables>Select Pathway</MenuItem>
        {this.state.pathways.map((item, key) => {
          return (
            <MenuItem key={key} value={item.pathway_id}>{item.pathway_name}</MenuItem>
          )
        })}
      </Select>
    )
  }

  onPostClick() {
    // if message is blank but user wants to upload a image
    if (this.state.message === "" && this.state.logoSrc !== null) {
      this.setState({
        feedType: 2
      })
      let url = this.state.logoSrc
      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          var props = this.props;
          var state = this.state
          var component = this
          const ext = this.state.logoSrc.split(';')[0].split('/')[1];
          var random = Math.floor(Math.random() * 900000000000000000);
          var filename = 'pathway/newsfeed/' + random + '.' + ext;
          var params = {
            Key: filename,
            Body: blob,
            ContentType: blob.type,
            Bucket: 'tassel.com'
          };
          s3.upload(params, function (err, data, token) {

            fetch("https://api.tasselpathway.com/feed", {
              method: "POST",
              headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "token": props.token
              },
              credentials: "omit",
              body: JSON.stringify({
                district_id: state.districtId,
                school_id: state.schoolId,
                feed_type_id: state.feedType,
                owner_id: props.personId,
                audience_name: state.targetAudience,
                audience_id: state.specifiedTargetAudience,
                message: '',
                title: state.title,
                url: state.url,
                url_domain: state.urlDomain,
                url_title: state.urlTitle,
                url_description: state.urlDescription,
                aws_bucket: "tassel.com",
                image_aws_key: filename
              })
            })
              .then(res => res.json())
              .then((responseJson) => {
                let feedId = responseJson.message.insertId
                if (responseJson.error === false) {
                  state.specifiedPostViewers.forEach((item) => {
                    component.postToProfileFeed(feedId, item.person_id)
                  })
                }
              })
          })
        })
      // if the logoSrc AND message have content we post both 
    } else if (this.state.logoSrc !== null && this.state.message !== '') {
      this.setState({ feedType: 2 })
      let url = this.state.logoSrc
      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          var props = this.props;
          var state = this.state
          var component = this
          const ext = this.state.logoSrc.split(';')[0].split('/')[1];
          var random = Math.floor(Math.random() * 900000000000000000);
          var filename = 'pathway/newsfeed/' + random + '.' + ext;
          var params = {
            Key: filename,
            Body: blob,
            ContentType: blob.type,
            Bucket: 'tassel.com'
          };
          s3.upload(params, function (err, data, token) {

            fetch("https://api.tasselpathway.com/feed", {
              method: "POST",
              headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "token": props.token
              },
              credentials: "omit",
              body: JSON.stringify({
                district_id: state.districtId,
                school_id: state.schoolId,
                feed_type_id: state.feedType,
                owner_id: props.personId,
                audience_name: state.targetAudience,
                audience_id: state.specifiedTargetAudience,
                message: state.message,
                title: state.title,
                url: state.url,
                url_domain: state.urlDomain,
                url_title: state.urlTitle,
                url_description: state.urlDescription,
                aws_bucket: "tassel.com",
                image_aws_key: filename
              })
            })
              .then(res => res.json())
              .then((responseJson) => {
                let feedId = responseJson.message.insertId
                if (responseJson.error === false) {
                  state.specifiedPostViewers.forEach((item) => {
                    component.postToProfileFeed(feedId, item.person_id)
                  })
                }
              })
          })
        })
    } else if (this.state.message !== "" && this.state.logoSrc === null) {
      this.setState({ feedType: 4 })
      fetch("https://api.tasselpathway.com/feed", {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "token": this.props.token
        },
        credentials: "omit",
        body: JSON.stringify({
          district_id: this.state.districtId,
          school_id: this.state.schoolId,
          feed_type_id: this.state.feedType,
          owner_id: this.props.personId,
          audience_name: this.state.targetAudience,
          audience_id: this.state.specifiedTargetAudience,
          message: this.state.message,
          title: this.state.title,
          url: this.state.url,
          url_domain: this.state.urlDomain,
          url_title: this.state.urlTitle,
          url_description: this.state.urlDescription,
          aws_bucket: "",
          image_aws_key: ""
        })
      })
        .then(res => res.json())
        .then((responseJson) => {
          let feedId = responseJson.message.insertId
          if (responseJson.error === false) {
            this.state.specifiedPostViewers.forEach((item) => {
              this.postToProfileFeed(feedId, item.person_id)
            })
          }
        })
    }
  }

  postToProfileFeed(feedId, personId) {
    fetch("https://api.tasselpathway.com/profile_feed", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: 'omit',
      body: JSON.stringify({
        feed_id: feedId,
        person_id: personId,
        reaction_id: this.state.reaction
      })
    })
      .then(res => res.json())
      .then(responseJson => {
        this.setState({
          logoSrc: null,
          message: '',
          feedType: 0,
          targetAudience: "Target Audience",
          specifiedTargetAudience: 0
        })
        this.getData(this.state.schoolId)
      })
  }

  fileChangedHandler(event) {
    var fileInput = false
    if (event.target.files[0]) {
      fileInput = true
    }
    if (fileInput) {
      Resizer.imageFileResizer(
        event.target.files[0],
        520,
        450,
        'PNG',
        1000,
        0,
        uri => {
          this.setState({
            logoSrc: uri
          })
        },
        'base64'
      );
    }
  }

  openEditDeleteLink(event, item) {
    let postNumber = parseInt(event.target.id)
    this.setState({
      anchor: event.currentTarget,
      postToDelete: postNumber,
      selectedItem: item
    })
  }
  closeEditDeleteLink() {
    this.setState({
      anchor: null,
      postToDelete: 0
    })
  }
  onDeleteOpen() {
    this.setState({
      onDeleteOpen: true
    })
  }
  onDeleteClose() {
    this.setState({
      onDeleteOpen: false
    })
    this.closeEditDeleteLink()
  }
  onEditOpen() {
    this.setState({
      isEditOpen: true
    })
  }
  onEditClose() {
    this.setState({
      isEditOpen: false,
    })
    this.getData(this.state.schoolId)
    this.closeEditDeleteLink()
  }
  onAudienceOpen() {
    this.setState({
      isAudienceOpen: true
    })
  }
  onAudienceCloseModal() {
    this.setState({
      isAudienceOpen: false,
      specifiedTargetAudience: 0,
      targetAudience: "Target Audience"
    })
  }
  onAudienceClose() {
    this.onPostClick()

    this.setState({
      isAudienceOpen: false
    })
  }

  onAudiencePost() {
    if (this.state.targetAudience !== "Target Audience") {
      this.onPostClick()
      this.setState({
        isAudienceOpen: false,
        message: ''
      })
      this.getData(this.state.schoolId)
    } else {
      alert('Please select an audience before posting')
    }
  }

  onAudienceCancel() {
    this.setState({
      isAudienceOpen: false,
      specifiedTargetAudience: 0,
      targetAudience: "Target Audience"
    })
  }
  onPostDemoOpen() {
    this.setState({
      postDemoOpen: true
    })
  }
  onPostDemoClose() {
    this.setState({
      postDemoOpen: false
    })
  }
  onMessageEdit(e) {
    var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    var regex = new RegExp(expression);
    var t = e.target.value;

    if (t.match(regex)) {
      let selectedItem = this.state.selectedItem
      selectedItem.message = e.target.value
      selectedItem.feed_type_id = 1
      this.setState({
        selectedItem: selectedItem,
      })
    } else if (this.state.selectedItem.image_aws_key !== "") {
      let selectedItem = this.state.selectedItem
      selectedItem.message = e.target.value
      selectedItem.feed_type_id = 2
      this.setState({
        selectedItem: selectedItem,
      })
    } else {
      let selectedItem = this.state.selectedItem
      selectedItem.message = e.target.value
      selectedItem.feed_type_id = 4
      this.setState({
        selectedItem: selectedItem,
      })
    }
  }
  onAudienceEdit(e) {
    let selectedItem = this.state.selectedItem
    selectedItem.audience_name = e.target.value
    this.setState({
      selectedItem
    })
  }
  onSpecifiedAudienceEdit(e) {
    let selectedItem = this.state.selectedItem
    selectedItem.audience_id = e.target.value
    if (this.state.selectedItem.audience_name === "By Grade") {
      fetch("https://api.tasselpathway.com/persons/class/" + e.target.value, {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "token": this.props.token
        },
        credentials: "omit"
      })
        .then(res => res.json())
        .then(responseJson => {
          this.setState({
            selectedItem
          })
        })
    } else if (this.state.selectedItem.audience_name === "By Pathway") {

      fetch("https://api.tasselpathway.com/persons/pathway/" + e.target.value, {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "token": this.props.token
        },
        credentials: "omit"
      })
        .then(res => res.json())
        .then(responseJson => {
          this.setState({
            selectedItem
          })
        })
    }
  }

  onDeletePost() {
    let postId = this.state.postToDelete
    fetch("https://api.tasselpathway.com/profile_feed/remove/" + postId, {
      method: 'DELETE',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit"
    })
      .then(res => res.json())
      .then(responseJson => {
        this.onDeleteClose();
        { responseJson.error === false ? this.getData(this.state.schoolId) : console.log("Error has occured") }
      })
  }
  onEditPost() {
    let selectedItem = this.state.selectedItem
    let postId = selectedItem.feed_id
    fetch("https://api.tasselpathway.com/feed/edit/" + postId, {
      method: "PATCH",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit",
      body: JSON.stringify({
        feed_type_id: this.state.selectedItem.feed_type_id,
        audience_name: this.state.selectedItem.audience_name,
        audience_id: this.state.selectedItem.audience_id,
        message: this.state.selectedItem.message
      })
    })
      .then(res => res.json())
      .then(responseJson => {
        if (responseJson.error === false) {
          this.onEditClose()
        }
      })
      .catch((error) => {
      })
  }
  renderPostBox() {
    const { classes } = this.props
    if (this.props.userRoleId < 8) {
      return (
        <Card className={classes.card} style={{ marginBottom: 10, borderRadius: 3 }}>
          <CardContent style={{ cursor: 'pointer' }}>
            {this.state.logoImageUrl === "" ?
              <Avatar aria-label="Recipe" src={require('../../assets/default_avatar.png')} alt='user profile image' className={classes.avatar} />
              :
              <Avatar aria-label="Recipe" src={this.state.logoImageUrl} alt='user profile image' className={classes.avatar} />
            }
            {this.props.selectedLanguage === "esp" ?
              <TextField
                id="standard-textarea"
                placeholder="Comparte una publicación"
                multiline
                InputProps={{ disableUnderline: true }}
                margin="normal"
                style={{ width: '85%', marginLeft: 5 }}
                onChange={(e) => this.handleMessageTextChange(e)}
                value={this.state.message}
              />
              :
              <TextField
                id="standard-textarea"
                placeholder="Share a post"
                multiline
                InputProps={{ disableUnderline: true }}
                margin="normal"
                style={{ width: '85%', marginLeft: 5 }}
                onChange={(e) => this.handleMessageTextChange(e)}
                value={this.state.message}
              />
            }
            <div>
              {this.state.logoSrc !== null ?
                <img src={this.state.logoSrc} style={{ width: '50%', marginTop: 12, marginBottom: -7 }} alt="Logo" />
                :
                null
              }
            </div>
          </CardContent>
          <CardContent style={{ marginTop: -10, marginBottom: -5 }}>
            <hr style={{ width: '95%', margin: 'auto', borderTopWidth: 1, borderTopColor: "#DADADA", borderTopStyle: 'solid', marginBottom: 10 }} />
            <input type="file" name="file" id="file" onChange={this.fileChangedHandler} style={{ width: 0.1, height: 0.1, opacity: 0, overflow: 'hidden', position: "absolute", zIndex: '1' }} />
            <label className='chooseFile' for="file">
              <Chip
                style={{ cursor: "pointer", marginLeft: 5, padding: 13, color: '#23272E', backgroundColor: '#F7F7F7' }}
                size="small"
                avatar={<FontAwesomeIcon style={{ fontSize: "5", color: "#23272E", cursor: "pointer", paddingRight: 3 }} icon={faCamera} />}
                label="Upload Photo"
              />
            </label>
            {/* <Chip
                style={{cursor:"pointer", marginLeft:10, padding:13, color:'#23272E', backgroundColor:'#F7F7F7'}}
                size="small"
                avatar={<FontAwesomeIcon style={{fontSize:1, color:"#23272E", cursor:"pointer", paddingRight:3}} icon={faUser} />}
                label="Share With"
                onClick={() => this.onAudienceOpen()}
              /> */}
            {this.state.logoSrc !== null ?

              <Chip
                style={{ cursor: "pointer", marginLeft: 10, padding: 13, color: '#23272E', backgroundColor: '#F7F7F7' }}
                size="small"
                avatar={<FontAwesomeIcon style={{ fontSize: 1, color: "#23272E", cursor: "pointer", paddingRight: 3 }} icon={faTrash} />}
                label="Remove Image"
                onClick={() => this.setState({ logoSrc: null })}
              />
              :
              null
            }
            <button type='button' style={styles.postButton} onClick={() => this.onAudienceOpen()}>{i18n.t("HOME_POST_BUTTON")}</button>

          </CardContent>
        </Card>
      )
    }
  }

  handleTabChange = (e, value) => {
    this.setState({
      TabValue: value
    })
  }

  render() {
    const { classes } = this.props;
    var i = 0;
    return (
      <div className={classes.root}>
        <Dialog onClose={this.onDeleteClose} aria-labelledby="customized-dialog-title" open={this.state.onDeleteOpen}>
          <DialogTitle id="customized-dialog-title" onClose={this.onDeleteClose}>
            <Typography style={{ fontSize: 18 }}>
              Delete Post?
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography>
              You can edit it if you just need to change something.
              If you wish to delete this post you can do so by clicking delete
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.onEditOpen()} color="primary">
              Edit
            </Button>
            <Button onClick={() => this.onDeletePost()} color="primary">
              Delete post
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog onClose={this.onEditClose} aria-labelledby="customized-dialog-title" open={this.state.isEditOpen} fullWidth={true}>
          <DialogTitle id="customized-dialog-title" onClose={this.onEditClose}>
            <Typography style={{ fontSize: 18 }}>
              Edit Post
            </Typography>
          </DialogTitle>
          <DialogContent>
            <FormControl style={{ width: '90%', marginLeft: '5%', marginBottom: 10 }} className={classes.formControl}>
              {this.state.logoImageUrl === "" ?
                <img style={{ height: 50, width: 50, borderRadius: 50 }} src={require('../../assets/default_avatar.png')} alt="user" />
                :
                <img src={this.state.logoImageUrl} style={{ height: 50, width: 50, borderRadius: 50 }} alt="user" />
              }
              <TextField
                id="standard-textarea"
                placeholder="Share a post"
                multiline
                InputProps={{ disableUnderline: true }}
                margin="normal"
                style={{ width: '85%', marginTop: -35, marginLeft: 65 }}
                onChange={(e) => this.onMessageEdit(e)}
                value={this.state.selectedItem.message}
              />
              {'image_aws_key' in this.state.selectedItem && this.state.selectedItem.image_aws_key !== '' ?
                <img style={{ height: 520, width: 450, margin: 'auto' }} src={this.state.selectedItem.image_aws_key} alt="uploaded" />
                : null}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.onEditClose()} color="primary">
              Discard
            </Button>
            <Button onClick={() => this.onEditPost()} color="primary">
              Done Editing
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog onClose={this.onAudienceClose} aria-labelledby="customized-dialog-title" open={this.state.isAudienceOpen} fullWidth={true}>
          <DialogTitle id="customized-dialog-title" onClose={this.onAudienceCloseModal}>
            <Typography style={{ fontSize: 18 }}>
              Who do you want to see this post?
            </Typography>
            <Typography style={{ fontSize: 14, marginLeft: 2 }}>
              Please specify an audience below.
            </Typography>
          </DialogTitle>
          <DialogContent>
            <FormControl style={{ width: '90%', marginLeft: '5%', marginBottom: 10 }} className={classes.formControl}>
              <Select
                value={this.state.targetAudience}
                onChange={(e) => this.handleAudienceChange(e)}
                name="age"
                displayEmpty
                className={classes.selectEmpty}
                style={{ marginLeft: -12 }}
                onBlur={() => console.log(this.state.targetAudience)}
              >
                <MenuItem value={'Target Audience'} disabled>
                  Target Audience
                </MenuItem>
                <MenuItem value={"Everyone"}>Everyone</MenuItem>
                <MenuItem value={"By Grade"}>By Grade</MenuItem>
                <MenuItem value={"By Pathway"}>By Pathway</MenuItem>

              </Select>
            </FormControl>
            {this.state.targetAudience === 'By Grade' ?
              <CardContent style={{}}>
                {this.renderGradeList()}
              </CardContent>

              : this.state.targetAudience === 'By Pathway' ?
                <CardContent style={{}}>
                  {this.renderPathwayList()}
                </CardContent>
                : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.onAudienceCloseModal()} color="primary">
              Close
            </Button>
            <Button onClick={() => this.onAudiencePost()} color="primary">
              Post
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog style={{ borderRadius: 3 }} onClose={this.onPostDemoClose} aria-labelledby="customized-dialog-title" open={this.state.postDemoOpen} fullWidth={true}>
          <DialogTitle id="customized-dialog-title" onClose={this.onPostDemoClose}>
            <Typography style={{ fontSize: 18, marginTop: 15 }}>
              Oops! Looks like you forgot to specify an audience.
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography style={{ fontSize: 14 }}>
              If you're not sure how to specify an audience, watch the video below.
            </Typography>
            <video style={{ height: 400, width: 575, overflow: 'hidden', marginLeft: -12, marginTop: -20 }} src={PostDemo} controls={true}></video>
          </DialogContent>
        </Dialog>

        <Grid container style={styles.itemContainerStyle} spacing={3}>

          <Grid item style={{ width: 250 }}>

            <Card className={classes.card} style={{ position: "fixed", width: 230, top: 75, borderRadius: 3 }}>

              <CardContent style={{ justifyContent: 'center', padding: 0, margin: 0, textAlign: 'center' }}>

                {this.state.bannerImageUrl === "" ?
                  <img src={require('../../assets/default_banner.png')} style={{ height: 75, width: '100%', objectFit: 'cover' }} alt="" />
                  :
                  <img src={this.state.bannerImageUrl} style={{ height: 75, width: '100%', objectFit: 'cover' }} alt="" />
                }

                {this.state.logoImageUrl === "" ?
                  this.props.taskId !== 0 && this.props.taskId !== undefined && this.props.taskId !== null ?
                    <img src={require('../../assets/default_avatar.png')}
                      style={{ borderRadius: 100, position: 'absolute', top: 40, marginLeft: -155, width: 75, height: 75 }} alt="" />
                    :
                    <img src={require('../../assets/default_avatar.png')}
                      style={{ borderRadius: 100, position: 'absolute', top: 40, marginLeft: -155, width: 75, height: 75 }} alt="" />
                  :
                  this.props.taskId !== 0 && this.props.taskId !== undefined && this.props.taskId !== null ?
                    <img src={this.state.logoImageUrl}
                      style={{ borderRadius: 100, position: 'absolute', top: 40, marginLeft: -155, width: 75, height: 75 }} alt="" />
                    :
                    <img src={this.state.logoImageUrl}
                      style={{ borderRadius: 100, position: 'absolute', top: 40, marginLeft: -155, width: 75, height: 75 }} alt="" />
                }

                <Typography style={{ marginTop: 45, fontSize: 16, fontWeight: 'bold' }}>{this.state.firstName} {this.state.lastName}</Typography>

                {this.props.userRoleId === 8 ?
                  <Typography style={{ fontSize: 11 }}>{this.state.gradeName}, {this.state.schoolName}</Typography>
                  :
                  <Typography style={{ fontSize: 11 }}>{this.state.schoolName}</Typography>
                }

              </CardContent>
              <CardActions className={classes.actions}>

              </CardActions>
            </Card>

            {this.props.userRoleId === 8 ?
              <div style={{ position: 'fixed', width: 230, top: 260 }}>
                <LinksScreen></LinksScreen>
              </div>
              : null}

            {this.props.userRoleId !== 8 ?
              <div style={{ position: 'fixed', width: 230, top: 260 }}>
                <LinksTeacherScreen></LinksTeacherScreen>
              </div>
              : null}

            <br />

            <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>

              <ImageList style={{ justifyContent: 'center', textAlign: 'center' }} className={classes.root}>

                {this.state.badges.map((item, key) => {
                  return (

                    <Avatar key={key} style={{ height: 75, width: 75, margin: 10 }} src={item.image_url} />
                  )
                })}

              </ImageList>

            </Grid>


          </Grid>

          <Grid item style={{ width: 500 }}>
            {this.renderPostBox()}
            {this.props.userRoleId < 8 ?
              this.state.teacherNewFeedData.map((item, key) => {
                if (item.feed_type_id === 1) {
                  const { classes } = this.props
                  return (
                    <>
                      <Card key={key} className={classes.card} style={{ borderRadius: 3 }}>
                        {item.logo_aws_key !== "" ?
                          <CardHeader
                            avatar={
                              <Avatar style={{ height: 50, width: 50 }} src={item.logo_aws_key} aria-label="Recipe" className={classes.avatar} />
                            }

                            action={
                              <IconButton>
                                {item.owner_id === this.props.personId || this.props.userRoleId <= 4 ?
                                  <MoreHorizIcon id={item.feed_id} onClick={(event) => this.openEditDeleteLink(event, item)} />
                                  : null
                                }
                                <Menu
                                  id="simple-menu"
                                  anchorEl={this.state.anchor}
                                  keepMounted
                                  open={Boolean(this.state.anchor)}
                                  elevation={0}
                                  onClose={() => this.closeEditDeleteLink()}>
                                  <MenuItem onClick={() => this.onEditOpen()}>Edit</MenuItem>
                                  <MenuItem onClick={() => this.onDeleteOpen()}>Delete</MenuItem>
                                </Menu>

                              </IconButton>
                            }
                            title={item.first_name + " " + item.last_name}
                            titleTypographyProps={{ style: { fontWeight: 'bold' } }}
                            subheader={moment(item.date_created).utc(true).format("MMM DD - hh:mm: A")}
                          />

                          :
                          <CardHeader
                            avatar={
                              <Avatar style={{ height: 50, width: 50 }} src={require('../../assets/default_avatar.png')} aria-label="Recipe" className={classes.avatar} />
                            }
                            action={
                              <IconButton>
                                {item.owner_id === this.props.personId || this.props.userRoleId <= 4 ?
                                  <MoreHorizIcon id={item.feed_id} onClick={(event) => this.openEditDeleteLink(event, item)} />
                                  : null
                                }
                                <Menu
                                  id="simple-menu"
                                  anchorEl={this.state.anchor}
                                  keepMounted
                                  open={Boolean(this.state.anchor)}
                                  elevation={1}
                                  onClose={() => this.closeEditDeleteLink()}>
                                  <MenuItem onClick={() => this.onEditOpen()}>Edit</MenuItem>
                                  <MenuItem onClick={() => this.onDeleteOpen()}>Delete</MenuItem>
                                </Menu>
                              </IconButton>
                            }
                            title={item.first_name + " " + item.last_name}
                            titleTypographyProps={{ style: { fontWeight: 'bold' } }}
                            subheader={moment(item.date_created).utc(true).format("MMM DD - hh:mm: A")}
                          />

                        }
                        <CardContent style={{ backgroundColor: '#fff', cursor: 'pointer' }}>

                          <Typography component="p">
                            {item.message !== '' ? <Linkify>{item.message}</Linkify> : null}
                          </Typography>
                        </CardContent>
                        <CardActions style={{ marginTop: -10 }} className={classes.actions}>

                        </CardActions>
                      </Card>
                      <br />
                    </>
                  )
                } else if (item.feed_type_id === 2) {
                  const { classes } = this.props
                  return (
                    <>
                      <Card key={key} className={classes.card} style={{ borderRadius: 3 }}>
                        {item.logo_aws_key !== "" ?
                          <CardHeader
                            avatar={
                              <Avatar style={{ height: 50, width: 50 }} src={item.logo_aws_key} aria-label="Recipe" className={classes.avatar} />
                            }
                            action={
                              <IconButton>
                                {item.owner_id === this.props.personId || this.props.userRoleId <= 4 ?
                                  <MoreHorizIcon id={item.feed_id} onClick={(event) => this.openEditDeleteLink(event, item)} />
                                  : null
                                }
                                <Menu
                                  id="simple-menu"
                                  anchorEl={this.state.anchor}
                                  keepMounted
                                  open={Boolean(this.state.anchor)}
                                  elevation={0}
                                  onClose={() => this.closeEditDeleteLink()}>
                                  <MenuItem onClick={() => this.onEditOpen()}>Edit</MenuItem>
                                  <MenuItem onClick={() => this.onDeleteOpen()}>Delete</MenuItem>
                                </Menu>
                              </IconButton>
                            }
                            title={item.first_name + " " + item.last_name}
                            titleTypographyProps={{ style: { fontWeight: 'bold' } }}
                            subheader={moment(item.date_created).utc(true).format("MMM DD - hh:mm: A")}
                          />

                          :
                          <CardHeader
                            avatar={
                              <Avatar style={{ height: 50, width: 50 }} src={require('../../assets/default_avatar.png')} aria-label="Recipe" className={classes.avatar} />
                            }
                            action={
                              <IconButton>
                                {item.owner_id === this.props.personId || this.props.userRoleId <= 4 ?
                                  <MoreHorizIcon id={item.feed_id} onClick={(event) => this.openEditDeleteLink(event, item)} />
                                  : null
                                }
                                <Menu
                                  id="simple-menu"
                                  anchorEl={this.state.anchor}
                                  keepMounted
                                  open={Boolean(this.state.anchor)}
                                  elevation={1}
                                  onClose={() => this.closeEditDeleteLink()}>
                                  <MenuItem onClick={() => this.onEditOpen()}>Edit</MenuItem>
                                  <MenuItem onClick={() => this.onDeleteOpen()}>Delete</MenuItem>
                                </Menu>
                              </IconButton>
                            }
                            title={item.first_name + " " + item.last_name}
                            titleTypographyProps={{ style: { fontWeight: 'bold' } }}
                            subheader={moment(item.date_created).utc(true).format("MMM DD - hh:mm: A")}
                          />

                        }
                        <CardContent style={{ backgroundColor: '#fff', cursor: 'pointer' }}>

                          <Typography component="p">
                            {item.message !== '' ? item.message : null}
                          </Typography>
                          <img style={{ height: 520, width: 450 }} src={item.image_aws_key} alt="" />
                        </CardContent>
                        <CardActions style={{ marginTop: -10 }} className={classes.actions}>

                        </CardActions>
                      </Card>
                      <br />
                    </>
                  )
                } else if (item.feed_type_id === 3) {

                } else if (item.feed_type_id === 4) {
                  const classes = this.props
                  return (
                    <>
                      <Card key={key} id={item.feed_id} className={classes.card} style={{ borderRadius: 3 }}>
                        {item.logo_aws_key !== "" ?
                          <CardHeader
                            avatar={
                              <Avatar style={{ height: 50, width: 50 }} src={item.logo_aws_key} aria-label="Recipe" className={classes.avatar} />
                            }
                            action={
                              <IconButton>
                                {item.owner_id === this.props.personId || this.props.userRoleId <= 4 ?
                                  <MoreHorizIcon id={item.feed_id} onClick={(event) => this.openEditDeleteLink(event, item)} />
                                  : null
                                }
                                <Menu
                                  id="simple-menu"
                                  anchorEl={this.state.anchor}
                                  keepMounted
                                  className={classes.root}
                                  open={Boolean(this.state.anchor)}
                                  elevation={0}
                                  onClose={() => this.closeEditDeleteLink()}>
                                  <MenuItem onClick={() => this.onEditOpen()}>Edit</MenuItem>
                                  <MenuItem onClick={() => this.onDeleteOpen()}>Delete</MenuItem>
                                </Menu>
                              </IconButton>
                            }
                            title={item.first_name + " " + item.last_name}
                            titleTypographyProps={{ style: { fontWeight: 'bold', marginTop: -5 } }}
                            subheaderTypographyProps={{ style: { marginTop: 5 } }}
                            subheader={moment(item.date_created).utc(true).format("MMM DD - hh:mm: A")}
                          />

                          :
                          <CardHeader key={key}
                            avatar={
                              <Avatar style={{ height: 50, width: 50 }} src={require('../../assets/default_avatar.png')} aria-label="Recipe" className={classes.avatar} />
                            }
                            action={
                              <IconButton>
                                {item.owner_id === this.props.personId || this.props.userRoleId <= 4 ?
                                  <MoreHorizIcon id={item.feed_id} onClick={(event) => this.openEditDeleteLink(event, item)} />
                                  : null
                                }
                                <Menu
                                  id="simple-menu"
                                  anchorEl={this.state.anchor}
                                  keepMounted
                                  open={Boolean(this.state.anchor)}
                                  elevation={1}
                                  onClose={() => this.closeEditDeleteLink()}>
                                  <MenuItem onClick={(e) => this.onEditOpen()}>Edit</MenuItem>
                                  <MenuItem onClick={() => this.onDeleteOpen()}>Delete</MenuItem>
                                </Menu>
                              </IconButton>
                            }
                            title={item.first_name + " " + item.last_name}
                            titleTypographyProps={{ style: { fontWeight: 'bold' } }}
                            subheader={moment(item.date_created).utc(true).format("MMM DD - hh:mm: A")}
                          />
                        }
                        <CardContent style={{ backgroundColor: '#fff', cursor: 'pointer' }}>

                          <Typography component="p">
                            {item.message}
                          </Typography>
                        </CardContent>
                        <CardActions style={{ marginTop: -10 }} className={classes.actions}>

                        </CardActions>
                      </Card>
                      <br />
                    </>
                  )
                }
              })

              :
              this.props.userRoleId === 8 && this.state.newsFeedData.length > 0 ?
                this.state.newsFeedData.map((item, key) => {
                  if (item.feed_type_id === 1) {
                    const { classes } = this.props
                    return (
                      <>
                        <Card key={key} className={classes.card} style={{ borderRadius: 3 }}>
                          {item.logo_aws_key !== "" ?
                            <CardHeader
                              avatar={
                                <Avatar style={{ height: 50, width: 50 }} src={item.logo_aws_key} aria-label="Recipe" className={classes.avatar} />
                              }
                              action={
                                <IconButton>

                                </IconButton>
                              }
                              title={item.first_name + " " + item.last_name}
                              titleTypographyProps={{ style: { fontWeight: 'bold' } }}
                              subheader=""
                            />

                            :
                            <CardHeader
                              avatar={
                                <Avatar style={{ height: 50, width: 50 }} src={require('../../assets/default_avatar.png')} aria-label="Recipe" className={classes.avatar} />
                              }
                              action={
                                <IconButton>

                                </IconButton>
                              }
                              title={item.first_name + " " + item.last_name}
                              titleTypographyProps={{ style: { fontWeight: 'bold' } }}
                              subheader=""
                            />

                          }
                          <CardContent style={{ backgroundColor: '#fff', cursor: 'pointer' }}>

                            <Typography component="p">
                              {item.message !== '' ? <Linkify>{item.message}</Linkify> : null}
                            </Typography>
                          </CardContent>

                          <CardActions style={{ marginTop: -10 }} className={classes.actions}>

                          </CardActions>
                        </Card>
                        <br />
                      </>
                    )
                  } else if (item.feed_type_id === 2) {
                    const { classes } = this.props
                    return (
                      <>
                        <Card key={key} className={classes.card} style={{ borderRadius: 3 }}>
                          {item.logo_aws_key !== "" ?
                            <CardHeader
                              avatar={
                                <Avatar style={{ height: 50, width: 50 }} src={item.logo_aws_key} aria-label="Recipe" className={classes.avatar} />
                              }
                              action={
                                <IconButton>

                                </IconButton>
                              }
                              title={item.first_name + " " + item.last_name}
                              titleTypographyProps={{ style: { fontWeight: 'bold' } }}
                              subheader=""
                            />

                            :
                            <CardHeader
                              avatar={
                                <Avatar style={{ height: 50, width: 50 }} src={require('../../assets/default_avatar.png')} aria-label="Recipe" className={classes.avatar} />
                              }
                              action={
                                <IconButton>

                                </IconButton>
                              }
                              title={item.first_name + " " + item.last_name}
                              titleTypographyProps={{ style: { fontWeight: 'bold' } }}
                              subheader=""
                            />

                          }
                          <CardContent style={{ backgroundColor: '#fff', cursor: 'pointer' }}>

                            <Typography component="p">
                              {item.message !== '' ? item.message : null}
                            </Typography>
                            <img style={{ height: 520, width: 450 }} src={item.image_aws_key} alt="Upload" />
                          </CardContent>

                          <CardActions style={{ marginTop: -10 }} className={classes.actions}>

                          </CardActions>
                        </Card>
                        <br />
                      </>
                    )
                  } else if (item.feed_type_id === 3) {

                  } else if (item.feed_type_id === 4) {
                    const classes = this.props
                    return (
                      <>
                        <Card key={key} className={classes.card} style={{ borderRadius: 3 }}>
                          {item.logo_aws_key !== "" ?
                            <CardHeader
                              avatar={
                                <Avatar style={{ height: 50, width: 50 }} src={item.logo_aws_key} aria-label="Recipe" className={classes.avatar} />
                              }
                              action={
                                <IconButton>

                                </IconButton>
                              }
                              title={item.first_name + " " + item.last_name}
                              titleTypographyProps={{ style: { fontWeight: 'bold' } }}
                              subheader=""
                            />

                            :
                            <CardHeader
                              avatar={
                                <Avatar style={{ height: 50, width: 50 }} src={require('../../assets/default_avatar.png')} aria-label="Recipe" className={classes.avatar} />
                              }
                              action={
                                <IconButton>

                                </IconButton>
                              }
                              title={item.first_name + " " + item.last_name}
                              titleTypographyProps={{ style: { fontWeight: 'bold' } }}
                              subheader=""
                            />
                          }

                          <CardContent style={{ backgroundColor: '#fff', cursor: 'pointer' }}>

                            <Typography component="p">
                              {item.message}
                            </Typography>
                          </CardContent>

                          <CardActions style={{ marginTop: -10 }} className={classes.actions}>

                          </CardActions>
                        </Card>
                        <br />
                      </>
                    )
                  }
                })
                :

                <div style={{ textAlign: 'center', paddingTop: 40 }}>
                  <img src={NoData} height='400' alt="No Data" />
                  <h4>No Posts Yet</h4>
                </div>
            }
          </Grid>
          <Grid item style={{ width: 300 }}>

            {this.props.userRoleId === 8 ?
              <ILPProgressScreen />
              : null}

            <div>
              <Typography
                style={{
                  fontSize: 18,
                  marginBottom: 10,
                  fontWeight: 'bold',
                  marginTop: 20,
                  color: '#4D4D4F'
                }}>{i18n.t("HOME_LEADER_BOARD")}</Typography>

              <Tabs value={this.state.TabValue} onChange={this.handleTabChange}>
                <Tab label="SCHOOL" />
                <Tab label="DISTRICT" />
              </Tabs>

              {this.state.TabValue === 0 && <StudentLeaderboard student_leaders={this.state.student_leaders} />}
              {this.state.TabValue === 1 && <DistrictLeaderboard district_leaders={this.state.district_leaders} />}

            </div>

          </Grid>
        </Grid>
      </div>
    );
  }
}

DashboardScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    personId: state.auth.personId,
    userRoleId: state.auth.userRoleId,
    districtId: state.profile.districtId,
    taskId: state.task.taskId,
    selectedLanguage: state.language.selectedLanguage,
    announcementAlert: state.alert.announcementAlert,
    urgentAlert: state.alert.urgentAlert
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation("translate"))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DashboardScreen)));