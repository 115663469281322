import {
    Button, Card, CardContent, DialogContentText, Grid, MenuItem, Select, Tab, Tabs, Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import { setProfile } from '../../redux/actions/profile';
import styles from './style';

import Achievement from "./achievement/index";
import Activity from "./activity/index";
import Assessment from "./assessment/index";
import Bio from "./bio/index";
import Career from "./career/index";
import Checklist from "./checklist/index";
import Civic from "./civic/index";
import StudentClassrooms from "./classrooms/index";
import Cluster from "./cluster/index";
import College from "./college/index";
import Connection from "./connection/index";
import Education from "./education/index";
import Goal from "./goal/index";
import Gpa from "./gpa/index";
import Interest from "./interest/index";
import Learning from './learning/index';
import Pathway from "./pathway/index";
import Project from "./project/index";
import Publication from "./publication/index";
import Recommendation from "./recommendation/index";
import Resume from "./resume/index";
import Service from "./service/index";
import Skill from "./skill/index";
import Work from "./work/index";

import Adaptability from "./adaptability/index";
import Communication from "./communication/index";
import Diligence from "./diligence/index";
import DrugFree from "./drugfree/index";
import Initiative from "./initiative/index";
import Knowledge from "./knowledge/index";
import Reliability from "./reliability/index";
import WorkReady from "./workready/index";

import { faPencilAlt, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import { DialogActions, DialogContent, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { Document, Font, Image, PDFViewer, Page, Text, View } from '@react-pdf/renderer';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import InputMask from 'react-input-mask';
import Heebo from '../../assets/fonts/heebo/Heebo-Light.ttf';
import Lato from '../../assets/fonts/lato/Lato-Bold.ttf';
import { setLanguage } from '../../redux/actions/language';
import ILPProgressScreen from '../shared/ilpprogress/index';
import './styles.css';
import WorkExperienceScreen from './workexperience/index';

var AWS = require('aws-sdk')

var S3 = new AWS.S3({
    accessKeyId: 'AKIARE5YHMQIWMYAOF6S',
    secretAccessKey: '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',

    region: 'us-east-1'
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" onClick={onClose}
                    sx={{ position: 'absolute', right: 10, top: 20 }} >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


Font.register({
    family: 'Heebo',
    src: Heebo,
});

Font.register({
    family: 'Lato',
    src: Lato,
})


class ProfileScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            dataSource: [],
            grade_options: [],
            assessmentData: [],
            value: 1,
            tabValue: 0,
            logoPreview: null,
            logoPreviewBanner: null,
            setImageUploadOpen: false,
            setBannerUploadOpen: false,
            openEditProfile: false,
            isSettingsOpen: false,
            userCurrentImage: "",
            bannerPreview: "",
            src: null,
            editedFirstName: '',
            editedLastName: '',
            editedPhoneNumber: '',
            logoSrc: null,
            bannerSrc: null,
            crop: {},
            croppedBanner64: '',
            croppedLogo64: '',
            savingChanges: false,
            newsFeedData: [],
            teacherNewsfeedData: [],
            message: '',
            title: '',
            url: '',
            urlDomain: '',
            urlTitle: '',
            urlDescription: '',
            pathways: [],
            targetAudience: 'Target Audience',
            feedType: 0,
            selectedItem: {},
            emailAddress: '',
            editedEmail: '',
            isPrintProfileOpen: false,
            pathway: '',
            traits: [],
            subjects: [],
            learningStyle: [],
            skills: [],
            awards: [],
            clubs: [],
            assessments: [],
            projects: [],
            colleges: [],
            careers: [],
            phoneNumber: ''
        }
        this.onCrop = this.onCrop.bind(this)
        this.onClose = this.onClose.bind(this)
        this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this)
        this.onSaveChangeClick = this.onSaveChangeClick.bind(this)
    }

    componentDidMount() {
        this.getData(this.props.match.params.profileId)
    }

    componentWillReceiveProps(newProps) {
        this.getData(newProps.match.params.profileId);
    }

    savingChangesClose = () => {
        this.setState({
            savingChanges: false
        })
    }

    getData(profileId) {
        fetch('https://api.tasselpathway.com/persons/' + profileId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false && responseJson.results[0].logo_image_url !== '') {
                    if (responseJson.results[0].banner_image_url !== '') {
                        this.setState({
                            isLoading: false,
                            bannerImageUrl: responseJson.results[0].banner_image_url,
                            logoImageUrl: responseJson.results[0].logo_image_url,
                            logoPreview: responseJson.results[0].logo_image_url,
                            bannerPreview: responseJson.results[0].banner_image_url
                        })
                    } else {
                        this.setState({
                            isLoading: false,
                            bannerImageUrl: require('../../assets/default_banner.png'),
                            logoImageUrl: responseJson.results[0].logo_image_url,
                            logoPreview: responseJson.results[0].logo_image_url,
                            bannerPreview: require('../../assets/default_banner.png'),
                        })
                    }

                    this.props.onSetProfile(
                        responseJson.results[0].person_id,
                        responseJson.results[0].first_name,
                        responseJson.results[0].last_name,
                        responseJson.results[0].grade_id,
                        responseJson.results[0].grade_name,
                        responseJson.results[0].district_id,
                        responseJson.results[0].district_name,
                        responseJson.results[0].schools[0].school_id,
                        responseJson.results[0].schools[0].school_name,
                        responseJson.results[0].role_id,
                        responseJson.results[0].early_graduation_program

                    );

                    this.setState({
                        emailAddress: responseJson.results[0].email_address,
                        phoneNumber: responseJson.results[0].phone,
                    })

                } else if (responseJson.error === false && responseJson.results[0].banner_image_url !== '') {
                    if (responseJson.results[0].logo_image_url !== '') {
                        this.setState({
                            isLoading: false,
                            bannerImageUrl: responseJson.results[0].banner_image_url,
                            logoImageUrl: responseJson.results[0].logo_image_url,
                            logoPreview: responseJson.results[0].logo_image_url,
                            bannerPreview: responseJson.results[0].banner_image_url
                        })
                    } else {
                        this.setState({
                            isLoading: false,
                            bannerImageUrl: responseJson.results[0].banner_image_url,
                            logoImageUrl: require('../../assets/default_avatar.png'),
                            logoPreview: require('../../assets/default_avatar.png'),
                            bannerPreview: responseJson.results[0].banner_image_url
                        })
                    }

                    this.props.onSetProfile(
                        responseJson.results[0].person_id,
                        responseJson.results[0].first_name,
                        responseJson.results[0].last_name,
                        responseJson.results[0].grade_id,
                        responseJson.results[0].grade_name,
                        responseJson.results[0].district_id,
                        responseJson.results[0].district_name,
                        responseJson.results[0].schools[0].school_id,
                        responseJson.results[0].schools[0].school_name,
                        responseJson.results[0].role_id,
                        responseJson.results[0].early_graduation_program
                    );

                    this.setState({
                        emailAddress: responseJson.results[0].email_address
                    })
                } else {
                    this.setState({
                        isLoading: false,
                        bannerImageUrl: require('../../assets/default_banner.png'),
                        logoImageUrl: require('../../assets/default_avatar.png'),
                        logoPreview: require('../../assets/default_avatar.png'),
                        bannerPreview: require('../../assets/default_banner.png'),
                    })

                    this.props.onSetProfile(
                        responseJson.results[0].person_id,
                        responseJson.results[0].first_name,
                        responseJson.results[0].last_name,
                        responseJson.results[0].grade_id,
                        responseJson.results[0].grade_name,
                        responseJson.results[0].district_id,
                        responseJson.results[0].district_name,
                        responseJson.results[0].schools[0].school_id,
                        responseJson.results[0].schools[0].school_name,
                        responseJson.results[0].role_id,
                        responseJson.results[0].early_graduation_program
                    );

                    this.setState({
                        emailAddress: responseJson.results[0].email_address
                    })
                }

                if (responseJson.authenticated === false) {
                    this.props.onLogout();
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            })
        fetch("https://api.tasselpathway.com/grades", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content_Type": "application/json",
                "token": this.props.token
            },
            credentials: "omit"
        })
            .then(res => res.json())
            .then(responseJson => {
                this.setState({
                    grade_options: responseJson.results
                })
            })

        fetch('https://api.tasselpathway.com/pathway', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        pathways: responseJson.results
                    })
                }
            })
    }

    getDistrictNewsfeed() {
        if (this.props.userRoleId < 8) {
            fetch("https://api.tasselpathway.com/feed/teacher/" + this.props.personId, {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": 'application/json',
                    "token": this.props.token
                },
                credentials: "omit"
            })
                .then(res => res.json())
                .then((responseJson) => {
                    this.setState({
                        teacherNewsfeedData: responseJson.results
                    })
                })
        }
    }

    handleImageUploadOpen = () => {
        this.setState({
            setImageUploadOpen: true,
            crop: {
                unit: '%',
                width: 100,
                aspect: 1 / 1
            },
        })
    };

    handleImageUploadClose = () => {
        this.setState({
            setImageUploadOpen: false,
            logoSrc: null
        })
    };

    handleBannerUploadOpen = () => {
        this.setState({
            setBannerUploadOpen: true,
            crop: {
                unit: '%',
                width: 100,
                aspect: 5 / 1
            }
        })
    };
    handleBannerUploadClose = () => {
        this.setState({
            setBannerUploadOpen: false
        })
    };


    handleEditProfileClick = () => {
        let firstName = this.props.firstName
        let lastName = this.props.lastName
        let education = this.props.education
        let email = this.state.emailAddress
        let phone = this.state.phoneNumber

        this.setState({
            openEditProfile: true,
            editedFirstName: firstName,
            editedLastName: lastName,
            editedEducation: education,
            editedEmail: email,
            editedPhoneNumber: phone
        })
    };

    handleEditProfileClose = () => {
        this.setState({
            openEditProfile: false,
            logoPreview: null,
            bannerPreview: null,
            selectedProfile: {}
        })
        this.timer = setTimeout(
            () => this.getData(this.props.match.params.profileId),
            9000,
        );
    };

    onClose() {
        this.setState({ setImageUploadOpen: false })
    }

    onCrop(logoPreview) {
        this.setState({
            logoPreview,
            userCurrentImage: logoPreview
        })
    }

    onBeforeFileLoad(elem) {
        if (elem.target.files[0].size > 2100000) {
            alert("File is too big!");
            elem.target.value = "";
        };
    }

    async onSaveBanner(base64, filePath) {

        var url = this.state.croppedBanner64;

        const response = await fetch(url)
            .then(res => res.blob())
            .then(blob => {
                const base64Data = new Buffer.from(base64.replace(/^data:image\/\w+;base64,/, ""), 'base64')

                let params = {
                    Key: filePath,
                    Body: base64Data,
                    ContentType: blob.type,
                    Bucket: 'tassel.com',
                    ContentEncoding: 'base64'
                }

                this.uploadAWS(params);

                return blob.type;
            })

        return response;
    }

    async onSaveLogo(base64, filePath) {


        var url = this.state.croppedLogo64;

        const response = await fetch(url)
            .then(res => res.blob())
            .then(blob => {
                const base64Data = new Buffer.from(base64.replace(/^data:image\/\w+;base64,/, ""), 'base64')

                let params = {
                    Key: filePath,
                    Body: base64Data,
                    ContentType: blob.type,
                    Bucket: 'tassel.com',
                    ContentEncoding: 'base64'
                }

                this.uploadAWS(params);

                return blob.type;
            })

        return response;
    }

    async uploadAWS(params) {
        await S3.upload(params, function (err, data, token) {
            { err ? console.log(err) : console.log("Params", params) }
            return ''
        })
    }

    async onDownloadImages(logoBase64, logoFilePath, bannerBase64, bannerFilePath) {
        return Promise.all([this.onSaveLogo(logoBase64, logoFilePath), this.onSaveBanner(bannerBase64, bannerFilePath)]);
    }

    async onDownloadLogo(logoBase64, logoFilePath) {
        return Promise.all([this.onSaveLogo(logoBase64, logoFilePath)])
    }

    async onDownloadBanner(bannerBase64, bannerFilePath) {
        return Promise.all([this.onSaveBanner(bannerBase64, bannerFilePath)])
    }

    onSaveChangeClick(logoBase64, bannerBase64, firstName, lastName, education, email, phone) {

        if (logoBase64 !== null && bannerBase64 !== null) {
            if (logoBase64.substring(0, 4) === "data" && bannerBase64.length > 10000) {
                var bannerFileName = Math.floor(Math.random() * 900000000000000000);
                var logoFileName = Math.floor(Math.random() * 900000000000000000);

                const bannerExt = logoBase64.split(';')[0].split('/')[1];
                const logoExt = bannerBase64.split(';')[0].split('/')[1];

                let bannerFilePath = "pathway/person/" + bannerFileName + '.' + bannerExt;
                let logoFilePath = "pathway/person/" + logoFileName + '.' + logoExt;
                let awsBucket = "tassel.com"
                this.onDownloadImages(logoBase64, logoFilePath, bannerBase64, bannerFilePath).then((result) => {

                    var component = this;
                    var props = this.props;

                    fetch('https://api.tasselpathway.com/persons/profile', {
                        method: "PATCH",
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": 'application/json',
                            "token": props.token
                        },
                        credentials: "omit",
                        body: JSON.stringify({
                            first_name: firstName,
                            last_name: lastName,
                            phone: phone,
                            email_address: email,
                            aws_bucket: awsBucket,
                            logo_aws_key: logoFilePath,
                            logo_content_type: result[0],
                            banner_aws_key: bannerFilePath,
                            banner_content_type: result[1],
                            person_id: props.profileId
                        })
                    })
                        .then(res => res.json())
                        .then((responseJson => {
                            fetch('https://api.tasselpathway.com/users/username', {
                                method: "PATCH",
                                headers: {
                                    "Accept": "application/json",
                                    "Content-Type": 'application/json',
                                    "token": props.token
                                },
                                credentials: "omit",
                                body: JSON.stringify({
                                    email_address: email,
                                    person_id: props.profileId
                                })
                            })
                                .then(res => res.json())
                                .then(() => component.handleEditProfileClose(),
                                    this.setState({
                                        savingChanges: true
                                    }))
                        }))

                    fetch('https://api.tasselpathway.com/profile_education', {
                        method: "PUT",
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": 'application/json',
                            "token": props.token
                        },
                        credentials: "omit",
                        body: JSON.stringify({
                            profile_education_id: education,
                            education_name: education
                        })
                    })
                        .then(res => res.json())
                        .then(() => fetch('https://api.tasselpathway.com/users/username', {
                            method: "PATCH",
                            headers: {
                                "Accept": "application/json",
                                "Content-Type": 'application/json',
                                "token": props.token
                            },
                            credentials: "omit",
                            body: JSON.stringify({
                                email_address: email,
                                person_id: props.profileId
                            })
                        })
                            .then(res => res.json())
                            .then(() => component.handleEditProfileClose(),
                                this.setState({
                                    savingChanges: true
                                }))
                        )
                })
            } else if (logoBase64.length > 10000 && bannerBase64.length < 10000) {

                logoFileName = Math.floor(Math.random() * 900000000000000000);
                const logoExt = logoBase64.split(';')[0].split('/')[1];
                let logoFilePath = "pathway/person/" + logoFileName + '.' + logoExt;

                this.onDownloadLogo(logoBase64, logoFilePath).then((result) => {
                    var component = this;
                    var props = this.props;

                    fetch('https://api.tasselpathway.com/persons', {
                        method: "PATCH",
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": 'application/json',
                            "token": props.token
                        },
                        credentials: "omit",
                        body: JSON.stringify({
                            first_name: firstName,
                            last_name: lastName,
                            phone: phone,
                            email_address: email,
                            aws_bucket: 'tassel.com',
                            logo_aws_key: logoFilePath,
                            logo_content_type: result[0],
                            person_id: props.profileId,
                        })
                    })
                        .then(res => res.json())
                        .then((response) => {
                            fetch('https://api.tasselpathway.com/users/username', {
                                method: "PATCH",
                                headers: {
                                    "Accept": "application/json",
                                    "Content-Type": 'application/json',
                                    "token": props.token
                                },
                                credentials: "omit",
                                body: JSON.stringify({
                                    email_address: email,
                                    person_id: props.profileId
                                })
                            })
                                .then(res => res.json())
                                .then((response) => {
                                    component.handleEditProfileClose()
                                    this.setState({
                                        savingChanges: true
                                    })
                                })
                        }
                        )
                })
            } else if (logoBase64.length < 10000 && bannerBase64.length > 10000) {

                bannerFileName = Math.floor(Math.random() * 900000000000000000);

                const bannerExt = bannerBase64.split(';')[0].split('/')[1];

                let bannerFilePath = "pathway/person/" + bannerFileName + '.' + bannerExt;

                this.onDownloadBanner(bannerBase64, bannerFilePath).then((result) => {

                    var component = this;
                    var props = this.props;

                    fetch('https://api.tasselpathway.com/persons/banner/', {
                        method: "PATCH",
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": 'application/json',
                            "token": props.token
                        },
                        credentials: "omit",
                        body: JSON.stringify({
                            first_name: firstName,
                            last_name: lastName,
                            email_address: email,
                            aws_bucket: 'tassel.com',
                            banner_aws_key: bannerFilePath,
                            banner_content_type: result[0],
                            person_id: props.profileId,
                        })
                    })
                        .then(res => res.json())
                        .then(() =>
                            fetch('https://api.tasselpathway.com/users/username', {
                                method: "PATCH",
                                headers: {
                                    "Accept": "application/json",
                                    "Content-Type": 'application/json',
                                    "token": props.token
                                },
                                credentials: "omit",
                                body: JSON.stringify({
                                    email_address: email,
                                    person_id: props.profileId
                                })
                            })
                                .then(res => res.json())
                                .then(() => component.handleEditProfileClose(),
                                    this.setState({
                                        savingChanges: true
                                    }))
                        )
                })
            } else {
                var component = this;
                var props = this.props;

                fetch('https://api.tasselpathway.com/persons/name', {
                    method: "PATCH",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": 'application/json',
                        "token": props.token
                    },
                    credentials: "omit",
                    body: JSON.stringify({
                        first_name: firstName,
                        last_name: lastName,
                        phone: phone,
                        email_address: email,
                        person_id: props.profileId,
                        district_id: props.districtId
                    })
                })
                    .then(res => res.json())
                    .then(() =>
                        fetch('https://api.tasselpathway.com/users/username', {
                            method: "PATCH",
                            headers: {
                                "Accept": "application/json",
                                "Content-Type": 'application/json',
                                "token": props.token
                            },
                            credentials: "omit",
                            body: JSON.stringify({
                                email_address: email,
                                person_id: props.profileId
                            })
                        })
                            .then(res => res.json())
                            .then(() => component.handleEditProfileClose(),
                                this.setState({
                                    savingChanges: true
                                }))
                    )
            }

            setTimeout(function () {
                this.setState({ savingChanges: false });
            }.bind(this), 12000);  // wait 12 seconds, then reset to false
        }
    }

    postToProfileFeed(feedId, personId) {
        fetch("https://api.tasselpathway.com/profile_feed", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "token": this.props.token
            },
            credentials: 'omit',
            body: JSON.stringify({
                feed_id: feedId,
                person_id: personId,
                reaction_id: this.state.reaction
            })
        })
            .then(res => res.json())
            .then(responseJson => {
                this.setState({
                    message: '',
                    feedType: 0,
                    targetAudience: "Target Audience"
                })
                this.componentDidMount()
            })
    }

    onPostEducationClick(education_name) {
        fetch("https://api.tasselpathway.com/profile_education", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "token": this.props.token
            },
            credentials: 'omit',
            body: JSON.stringify({
                person_id: this.props.personId,
                education_name: education_name,
                major: this.state.major
            })
        })
            .then(res => res.json())
            .then(responseJson => {
                this.getData();
                this.onPostEducationClose();
            })
    }

    onPostClick() {
        // if message is blank but user wants to upload a image
        if (this.state.message === "" && this.state.logoSrc !== null) {
            let url = this.state.logoSrc
            fetch(url)
                .then(res => res.blob())
                .then(blob => {

                    var props = this.props;
                    var state = this.state
                    var component = this
                    const ext = this.state.logoSrc.split(';')[0].split('/')[1];
                    var random = Math.floor(Math.random() * 900000000000000000);
                    var filename = 'pathway/newsfeed/' + random + '.' + ext;
                    var params = {
                        Key: filename,
                        Body: blob,
                        ContentType: blob.type,
                        Bucket: 'tassel.com'
                    };

                    S3.upload(params, function (err, data, token) {
                        {
                            err ? console.log(err.stack)
                                :
                                fetch("https://api.tasselpathway.com/feed", {
                                    method: "POST",
                                    headers: {
                                        "Accept": "application/json",
                                        "Content-Type": "application/json",
                                        "token": props.token
                                    },
                                    credentials: "omit",
                                    body: JSON.stringify({
                                        district_id: state.districtId,
                                        feed_type_id: state.feedType,
                                        owner_id: props.personId,
                                        audience_name: state.targetAudience,
                                        audience_id: state.specifiedTargetAudience,
                                        message: state.message,
                                        title: state.title,
                                        url: state.url,
                                        url_domain: state.urlDomain,
                                        url_title: state.urlTitle,
                                        url_description: state.urlDescription,
                                        aws_bucket: "tassel.com",
                                        image_aws_key: filename
                                    })
                                })
                                    .then(res => res.json())
                                    .then((responseJson) => {
                                        let feedId = responseJson.message.insertId

                                        if (responseJson.error === false) {
                                            state.specifiedPostViewers.forEach((item) => {
                                                component.postToProfileFeed(feedId, item.person_id)
                                            })
                                        }
                                    })
                        }
                    })
                })
            // if the logoSrc AND message have content we post both 
        } else if (this.state.logoSrc !== null && this.state.message !== '') {
            let url = this.state.logoSrc
            fetch(url)
                .then(res => res.blob())
                .then(blob => {

                    var props = this.props;
                    var state = this.state
                    var component = this
                    const ext = this.state.logoSrc.split(';')[0].split('/')[1];
                    var random = Math.floor(Math.random() * 900000000000000000);
                    var filename = 'pathway/newsfeed/' + random + '.' + ext;
                    var params = {
                        Key: filename,
                        Body: blob,
                        ContentType: blob.type,
                        Bucket: 'tassel.com'
                    };

                    S3.upload(params, function (err, data, token) {
                        {
                            err ? console.log(err.stack)
                                :
                                fetch("https://api.tasselpathway.com/feed", {
                                    method: "POST",
                                    headers: {
                                        "Accept": "application/json",
                                        "Content-Type": "application/json",
                                        "token": props.token
                                    },
                                    credentials: "omit",
                                    body: JSON.stringify({
                                        district_id: state.districtId,
                                        feed_type_id: state.feedType,
                                        owner_id: props.personId,
                                        audience_name: state.targetAudience,
                                        audience_id: state.specifiedTargetAudience,
                                        message: state.message,
                                        title: state.title,
                                        url: state.url,
                                        url_domain: state.urlDomain,
                                        url_title: state.urlTitle,
                                        url_description: state.urlDescription,
                                        aws_bucket: "tassel.com",
                                        image_aws_key: filename
                                    })
                                })
                                    .then(res => res.json())
                                    .then((responseJson) => {
                                        let feedId = responseJson.message.insertId

                                        if (responseJson.error === false) {
                                            state.specifiedPostViewers.forEach((item) => {
                                                component.postToProfileFeed(feedId, item.person_id)
                                            })
                                        }
                                    })
                        }
                    })
                })
            // if message has content and logoSrc is null (no attachment) post just the image
        } else if (this.state.message !== "" && this.state.logoSrc === null) {
            fetch("https://api.tasselpathway.com/feed", {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "token": this.props.token
                },
                credentials: "omit",
                body: JSON.stringify({
                    district_id: this.state.districtId,
                    feed_type_id: this.state.feedType,
                    owner_id: this.props.personId,
                    audience_name: this.state.targetAudience,
                    audience_id: this.state.specifiedTargetAudience,
                    message: this.state.message,
                    title: this.state.title,
                    url: this.state.url,
                    url_domain: this.state.urlDomain,
                    url_title: this.state.urlTitle,
                    url_description: this.state.urlDescription,
                    aws_bucket: "",
                    image_aws_key: ""
                })
            })
                .then(res => res.json())
                .then((responseJson) => {
                    let feedId = responseJson.message.insertId
                    if (responseJson.error === false) {
                        this.state.specifiedPostViewers.forEach((item) => {
                            this.postToProfileFeed(feedId, item.person_id)
                        })
                    }
                })
        }
    }

    onSelectBannerFile = e => {
        if (e.target.files && e.target.files.length > 0 && e.target.files[0].size < 3000000) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({ bannerSrc: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        } else {
            alert("This photo's file size is too big. Please select a photo less than 3 MB");
            e.target.value = "";
        }
    };

    onSelectLogoFile = e => {
        if (e.target.files && e.target.files.length > 0 && e.target.files[0].size < 3000000) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({ logoSrc: reader.result }),
            );
            reader.readAsDataURL(e.target.files[0]);
        } else {
            alert("This photo's file size is too big. Please select a photo less than 3 MB");
            e.target.value = "";
        }
    };

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onLogoCropComplete = crop => {
        this.makeClientCropLogo(crop);
    };

    onBannerCropComplete = crop => {
        this.makeClientCropBanner(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCropLogo(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const logoPreview = await this.getCroppedLogoImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ logoPreview });
        }
    }

    async makeClientCropBanner(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const bannerPreview = await this.getCroppedBannerImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ bannerPreview });
        }
    }

    getCroppedBannerImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;

                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);

                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    var base64data = reader.result;

                    this.setState({
                        croppedBanner64: base64data
                    })
                }
                resolve(this.fileUrl);
            }, "image/png");
        });
    }


    getCroppedLogoImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;

                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);

                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    var base64data = reader.result;

                    this.setState({
                        croppedLogo64: base64data
                    })
                }
                resolve(this.fileUrl);
            }, "image/jpeg");
        });
    }

    handleMessageTextChange(e) {
        var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
        var regex = new RegExp(expression);
        var t = e.target.value;

        if (t.match(regex)) {
            this.setState({
                message: e.target.value,
                feedType: 1
            })
        } else if (this.state.logoSrc !== null) {
            this.setState({
                message: e.target.value,
                feedType: 2
            })
        } else {
            this.setState({
                message: e.target.value,
                feedType: 4
            })
        }
    }
    handleAudienceChange(e) {
        this.setState({
            targetAudience: e.target.value
        })
        if (e.target.value === "Everyone") {

            fetch("https://api.tasselpathway.com/persons/district", {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "token": this.props.token
                },
                credentials: "omit"
            })
                .then(res => res.json())
                .then(responseJson => {
                    this.setState({
                        specifiedTargetAudience: 0,
                        specifiedPostViewers: responseJson.results
                    })
                })
        }

    }
    handleSelectedAudience(e) {
        let classId = e.target.value
        fetch("https://api.tasselpathway.com/persons/class/" + classId, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "token": this.props.token
            },
            credentials: "omit"
        })
            .then(res => res.json())
            .then(responseJson => {
                this.setState({
                    specifiedTargetAudience: e.target.value,
                    specifiedPostViewers: responseJson.results
                })
            })

    }
    handleSelectedPathway(e) {
        let pathwayId = e.target.value
        fetch("https://api.tasselpathway.com/persons/pathway/" + pathwayId, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "token": this.props.token
            },
            credentials: "omit"
        })
            .then(res => res.json())
            .then(responseJson => {
                this.setState({
                    specifiedTargetAudience: e.target.value,
                    specifiedPostViewers: responseJson.results
                })
            })
    }

    renderGradeList() {
        return (
            <Select
                style={{ width: '97%' }}
                value={this.state.specifiedTargetAudience}
                onChange={(e) => this.handleSelectedAudience(e)}
                name="age"
                displayEmpty>
                <MenuItem value={0} disables>Select Grade</MenuItem>
                {this.state.grade_options.map((item, key) => {
                    return (
                        <MenuItem key={key} value={item.grade_id}>{item.grade_name} Class</MenuItem>
                    )
                })}
            </Select>
        )
    }

    renderPathwayList() {
        return (
            <Select
                style={{ width: '97%', marginLeft: 1 }}
                value={this.state.specifiedTargetAudience}
                onChange={(e) => this.handleSelectedPathway(e)}
                name="age"
                displayEmpty
            >
                <MenuItem value={0} disables>Select Pathway</MenuItem>
                {this.state.pathways.map((item, key) => {
                    return (
                        <MenuItem key={key} value={item.pathway_id}>{item.pathway_name}</MenuItem>
                    )
                })}
            </Select>
        )
    }

    renderLoader() {
        return (
            <div style={{ position: 'absolute' }} className="lds-dual-ring" id="hideMeComplete"></div>
        )
    }

    printProfileOpen = () => {
        this.setState({
            isPrintProfileOpen: true
        })
    }

    printProfileClose = () => {
        this.setState({
            isPrintProfileOpen: false
        })
    }

    renderTraits() {
        let allTraits = this.state.traits
        let topFiveTraits = allTraits.slice(0, 5)

        var content = topFiveTraits.map((item, key) => {
            return (
                <Text key={key} style={styles.bulletPointHeaderTextLeft}>• {item.cluster_name} </Text>
            )
        })
        return content;
    }

    renderSubjects() {
        var content = this.state.subjects.map((item, key) => {
            return (
                <Text key={key} style={styles.bulletPointHeaderTextLeft}>• {item.group_name} </Text>
            )
        })
        return content;
    }

    renderLearning() {
        var content = this.state.learningStyle.map((item, key) => {
            return (
                <Text key={key} style={styles.bulletPointHeaderTextLeft}>• {item.learning_style_name}: {item.percentage}% </Text>
            )
        })
        return content;
    }

    renderSkills() {
        let allSkills = this.state.skills
        let topFiveSkills = allSkills.slice(0, 5)
        var content = topFiveSkills.map((item, key) => {
            return (
                <Text key={key} style={styles.bulletPointHeaderTextLeft}>• {item.skill_name}</Text>
            )
        })
        return content;
    }

    renderAwards() {
        let allAwards = this.state.awards
        let topFiveAwards = allAwards.slice(0, 5)
        var content = topFiveAwards.map((item, key) => {
            return (
                <>
                    <Text key={key} style={styles.bulletPointHeaderTextRight}>• {item.achievement_name.toUpperCase()}</Text>
                    <Text key={key} style={styles.bulletPointTextRight}>{item.achievement_type}</Text>
                    <Text key={key} style={styles.bulletPointTextRight}>{item.achievement_description}</Text>
                </>
            )
        })
        return content;
    }

    renderClubs() {
        let allClubs = this.state.clubs
        let topFiveClubs = allClubs.slice(0, 5)
        var content = topFiveClubs.map((item, key) => {
            return (
                <>
                    <Text key={key} style={styles.bulletPointHeaderTextRight}>• {item.activity_name.toUpperCase()}</Text>
                    <Text key={key} style={styles.bulletPointTextRight}>{item.activity_role}</Text>
                </>
            )
        })
        return content;
    }

    renderAssessments() {
        let allAssessments = this.state.assessments
        let topFiveAssessments = allAssessments.slice(0, 5)
        var content = topFiveAssessments.map((item, key) => {
            return (
                <>
                    <Text key={key} style={styles.bulletPointHeaderTextLeft}>• {item.assessment_type_name.toUpperCase()}</Text>
                    <Text key={key} style={styles.bulletPointTextLeft}>Year taken: {item.grade_name}</Text>
                    {item.composite_score > 0 ? <Text key={key} style={styles.bulletPointTextLeft}>Composite Score: {item.composite_score}</Text> : null}
                    {item.assessment_type_id === 2 ?
                        <>
                            <Text key={key} style={styles.bulletPointTextLeft}>Math: {item.math_score}</Text>
                            <Text key={key} style={styles.bulletPointTextLeft}>Science: {item.science_score}</Text>
                            <Text key={key} style={styles.bulletPointTextLeft}>English: {item.english_score}</Text>
                            <Text key={key} style={styles.bulletPointTextLeft}>Reading: {item.reading_score}</Text>
                        </>
                        : null
                    }
                    {item.assessment_type_id === 3 ?
                        <>
                            <Text key={key} style={styles.bulletPointTextLeft}>Reading: {item.reading_score}</Text>
                            <Text key={key} style={styles.bulletPointTextLeft}>Writing: {item.writing_score}</Text>
                            <Text key={key} style={styles.bulletPointTextLeft}>Math: {item.math_score}</Text>
                        </>
                        : null
                    }

                    {item.assessment_type_id === 5 ?
                        <>
                            <Text key={key} style={styles.bulletPointTextLeft}>Test Name: {item.assessment_name}</Text>
                            <Text key={key} style={styles.bulletPointTextLeft}>Composite Score:  {item.composite_score}</Text>
                        </>
                        : null
                    }


                </>
            )
        })
        return content;
    }

    renderProjects() {
        let allProjects = this.state.projects
        let topFiveProjects = allProjects.slice(0, 5)
        var content = topFiveProjects.map((item, key) => {
            return (
                <>
                    <Text key={key} style={styles.bulletPointHeaderTextRight}>• {item.project_name.toUpperCase()}</Text>
                    <Text key={key} style={styles.bulletPointTextRight}>{item.project_description}</Text>
                </>
            )
        })
        return content;
    }

    renderColleges() {
        let allColleges = this.state.colleges
        let topFiveColleges = allColleges.slice(0, 5)
        var content = topFiveColleges.map((item, key) => {
            return (
                <View key={key} style={{ display: 'flex', flexDirection: 'row' }}>
                    <Image src={item.logo_image_url}
                        style={{ height: 20, width: 20, marginLeft: 30, marginTop: 4.5, borderRadius: 10 }}
                        source={{
                            header: {
                                'Access-Control-Allow-Origin': '*'
                            }
                        }}
                        alt="collegeIcon" />
                    <Text style={styles.bulletPointHeaderTextRight}>{item.college_name.toUpperCase()}</Text>
                </View>
            )
        })
        return content;
    }

    renderCareers() {
        let allCareers = this.state.careers
        let topFiveCareers = allCareers.slice(0, 5)
        var content = topFiveCareers.map((item, key) => {
            return (
                <View key={key} style={{ display: 'flex', flexDirection: 'row', width: 180, flexWrap: 'wrap' }}>
                    <Image src={item.logo_image_url} style={{ height: 20, width: 20, marginLeft: 0, marginTop: 4.5, borderRadius: 10 }}
                        source={{
                            header: {
                                'Access-Control-Allow-Origin': '*'
                            }
                        }}
                        alt="collegeIcon" />
                    <Text style={styles.bulletPointHeaderTextRightCareers}>{item.career_name.toUpperCase()}</Text>
                </View>
            )
        })
        return content;
    }

    printProfile() {
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>

                            <Text style={styles.headerText}>
                                {this.props.firstName} {this.props.lastName}
                            </Text>
                        </View>
                        {this.state.schoolName !== null ?
                            <View style={{ width: 250 }}>
                                <Text style={styles.subHeaderText}>{this.props.gradeName}, {this.props.schoolName}</Text>
                                <Text style={styles.pathwayText}>Pathway: {this.state.pathway}</Text>
                            </View>
                            :
                            <View style={{ width: 250 }}>
                                <Text style={styles.subHeaderText}>{this.props.gradeName}</Text>
                                <Text style={styles.pathwayText}>Pathway: {this.state.pathway}</Text>
                            </View>
                        }


                        <Text style={styles.sectionHeaderTextLeft}>FAVORITE SUBJECTS</Text>
                        {this.renderSubjects()}

                        <Text style={styles.sectionHeaderTextLeft}>PERSONALITY TRAITS</Text>
                        {this.renderTraits()}

                        <Text style={styles.sectionHeaderTextLeft}>LEARNING STYLE</Text>
                        {this.renderLearning()}

                        <Text style={styles.sectionHeaderTextLeft}>SKILLS</Text>
                        {this.renderSkills()}

                    </View>

                    <View style={styles.section2}>
                        <Image src={require('../../assets/logoBlack.png')} style={{
                            marginVertical: -40,
                            marginHorizontal: 100, height: 100
                        }} alt="" />

                        <Text style={styles.sectionHeaderTextRight}>HONORS & AWARDS</Text>
                        {this.renderAwards()}

                        <Text style={styles.sectionHeaderTextRightSecond}>CLUBS & SPORTS</Text>
                        {this.renderClubs()}
                    </View>
                </Page>

                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>

                        <Text style={styles.sectionHeaderTextLeft}>ASSESSMENTS</Text>
                        {this.renderAssessments()}

                        <Text style={styles.sectionHeaderTextLeft}>TOP COLLEGES</Text>
                        {this.renderColleges()}

                    </View>

                    <View style={styles.section2}>
                        <Image src={require('../../assets/logoBlack.png')} style={{
                            marginVertical: -40,
                            marginHorizontal: 100, height: 100
                        }} alt="" />

                        <Text style={styles.sectionHeaderTextRightPage2}>PROJECTS</Text>
                        {this.renderProjects()}

                        <Text style={styles.sectionHeaderTextRightSecondTopCareers}>TOP CAREERS</Text>
                        <View style={{ marginTop: -25 }}>
                            {this.renderCareers()}
                        </View>
                    </View>
                </Page>
            </Document>
        )

    }

    onPathwayLoaded = (value) => {
        this.setState({
            pathway: value
        })
    }

    onTraitsLoaded = (value) => {
        this.setState({
            traits: value
        })
    }

    onSubjectsLoaded = (value) => {
        this.setState({
            subjects: value
        })
    }

    onLearningLoaded = (value) => {
        this.setState({
            learningStyle: value
        })
    }

    onSkillLoaded = (value) => {
        this.setState({
            skills: value
        })
    }

    onAwardsLoaded = (value) => {
        this.setState({
            awards: value
        })
    }

    onClubsLoaded = (value) => {
        this.setState({
            clubs: value
        })
    }

    onAssessmentsLoaded = (value) => {
        this.setState({
            assessments: value
        })
    }

    onProjectsLoaded = (value) => {
        this.setState({
            projects: value
        })
    }

    onCollegesLoaded = (value) => {
        this.setState({
            colleges: value
        })
    }

    onCareersLoaded = (value) => {
        this.setState({
            careers: value
        })
    }


    render() {
        const { classes } = this.props;
        const { bannerSrc, logoSrc } = this.state;
        return (
            <div className={classes.root}>

                <Dialog
                    fullScreen
                    open={this.state.isPrintProfileOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle onClose={this.printProfileClose} id="alert-dialog-title" titleStyle={{ textAlign: "center", marginBottom: 10 }}>Print or download profile below.</DialogTitle>
                    <PDFViewer style={{ minHeight: '100%' }}>
                        {this.printProfile()}
                    </PDFViewer>
                </Dialog>

                <Dialog
                    open={this.state.savingChanges}
                    onClose={this.savingChangesClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableBackdropClick={true}
                    maxWidth='sm'
                    fullWidth={true}>
                    <DialogTitle id="alert-dialog-title" titleStyle={{ textAlign: "center" }}><strong>Give us a second...</strong><br></br>We're saving your changes.</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div style={{ display: 'flex', justifyContent: 'space-around', padding: 20 }}>
                                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

                <Grid container style={styles.itemContainerStyle}>

                    <Grid item style={{ width: '80%' }}>

                        <div style={{ height: 200, backgroundColor: '#e0e0e0' }}>
                            {this.state.bannerImageUrl === "" ?
                                <img src={require('../../assets/default_banner.png')} style={{
                                    width: '100%', height: 200, objectFit: 'cover',
                                    borderRadius: 3
                                }} alt="" />
                                :
                                <img src={this.state.bannerImageUrl} style={{
                                    width: '100%', height: 200, objectFit: 'cover',
                                    borderRadius: 3
                                }} alt="" />
                            }
                        </div>

                        <Card style={{ borderRadius: 3 }}>
                            <CardContent>

                                {this.props.taskId === 0 ?
                                    <div style={{ position: 'absolute', top: 0, marginTop: 210, height: 150, width: 150, borderRadius: 100, border: '2px solid #FFF' }}>
                                        {this.state.logoImageUrl === "" ?
                                            <img src={require('../../assets/default_avatar.png')} style={{ borderRadius: 100, width: '100%', height: '100%' }} alt="" />
                                            :
                                            <img src={this.state.logoImageUrl} style={{ borderRadius: 100, width: '100%', height: '100%' }} alt="" />
                                        }
                                    </div>
                                    :
                                    <div style={{ position: 'absolute', top: 0, marginTop: 210, height: 160, width: 150, borderRadius: 100, border: '2px solid #FFF' }}>
                                        {this.state.logoImageUrl === "" ?
                                            <img src={require('../../assets/default_avatar.png')} style={{ borderRadius: 100, width: '100%', height: '100%' }} alt="" />
                                            :
                                            <img src={this.state.logoImageUrl} style={{ borderRadius: 100, width: '100%', height: '100%' }} alt="" />
                                        }
                                    </div>
                                }

                                <div style={{ marginLeft: 160 }}>
                                    <Grid container>
                                        <Grid item xs={8}>
                                            <Grid container style={{ width: '100%' }}>
                                                <div style={{ float: 'left' }}>
                                                    <Typography style={{ fontSize: 26 }}>{this.props.firstName} {this.props.lastName}</Typography>
                                                    {this.props.userRoleId === 8 ?
                                                        this.props.schoolName !== "" ?
                                                            <Typography style={{ fontSize: 16 }}>{this.props.gradeName}, {this.props.schoolName}</Typography>
                                                            :
                                                            <Typography style={{ fontSize: 16 }}>{this.props.gradeName}</Typography>
                                                        : null}
                                                </div>
                                                <div style={{ float: 'right' }}>

                                                </div>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <Grid container style={{ float: 'right', width: '100%' }}>

                                                <Dialog onClose={() => this.handleEditProfileClose()}
                                                    aria-labelledby="customized-dialog-title"
                                                    open={this.state.openEditProfile}
                                                    maxWidth='md'
                                                    style={{ maxWidth: 800, minWidth: 800, margin: 'auto', }}>

                                                    <DialogTitle id="customized-dialog-title" style={{ paddingRight: 50 }} onClose={this.handleEditProfileClose}>
                                                        Edit Profile
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <Grid item style={{ width: '100%' }}>

                                                            <div style={{ height: 125, backgroundColor: '#e0e0e0' }}>
                                                                {this.state.bannerImageUrl === "" ?
                                                                    <img src={require('../../assets/default_banner.png')} style={{ minWidth: 656, maxHeight: 135, minHeight: 135, objectFit: 'cover' }} alt="" />
                                                                    : this.state.bannerPreview === null ?
                                                                        <img src={this.state.bannerImageUrl} style={{ minWidth: 656, maxHeight: 125, minHeight: 125, objectFit: 'cover' }} alt="" />
                                                                        : <img src={this.state.bannerPreview} style={{ minWidth: 656, maxHeight: 125, minHeight: 125, objectFit: 'cover' }} alt="" />
                                                                }
                                                            </div>

                                                            <Card style={{ minHeight: 350, maxHeight: 350, borderRadius: 3 }}>
                                                                <CardContent>

                                                                    <div style={{ position: 'absolute', top: 0, marginTop: 165 }}>

                                                                        {this.state.logoImageUrl === "" ?
                                                                            <img src={require('../../assets/default_avatar.png')} style={{ borderRadius: 100, border: '2px solid #FFF', minWidth: 120, maxWidth: 120, minHeight: 120, maxHeight: 120 }} alt="" />
                                                                            : this.state.logoPreview === null ?

                                                                                <img src={this.state.logoPreview} style={{ borderRadius: 100, border: '2px solid #FFF', minWidth: 120, maxWidth: 120, minHeight: 120, maxHeight: 120 }} alt="" />

                                                                                : <img src={this.state.logoPreview} style={{ borderRadius: 100, border: '2px solid #FFF', minWidth: 120, maxWidth: 120, minHeight: 120, maxHeight: 120 }} alt="" />

                                                                        }
                                                                    </div>

                                                                    <div style={{ marginLeft: 160 }}>
                                                                        <Grid container>

                                                                            <Grid item xs={4}>
                                                                                <Grid container style={{ float: 'right', width: '100%' }}>

                                                                                    <Dialog onClose={() => this.handleImageUploadClose()}
                                                                                        aria-labelledby="customized-dialog-title"
                                                                                        open={this.state.setImageUploadOpen}
                                                                                        maxWidth='sm'
                                                                                        fullWidth={true}>
                                                                                        <DialogTitle id="customized-dialog-title" onClose={this.handleImageUploadClose}>
                                                                                        </DialogTitle>
                                                                                        <DialogContent>
                                                                                            <div>
                                                                                                <div style={{ textAlign: 'center' }}>
                                                                                                    <input type="file" name="file" id="file" onChange={this.onSelectLogoFile} style={{ width: 0.1, height: 0.1, opacity: 0, overflow: 'hidden', position: "absolute", zIndex: '1' }} />
                                                                                                    <label className='chooseFile' style={styles.inputLogo} for="file">Click to choose file</label>
                                                                                                </div>

                                                                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                                    {this.state.logoSrc && (
                                                                                                        <Fragment>
                                                                                                            <div style={{ maxWidth: 500, margin: 'auto', marginBottom: 0 }}>
                                                                                                                <ReactCrop
                                                                                                                    src={logoSrc}
                                                                                                                    crop={this.state.crop}
                                                                                                                    onImageLoaded={this.onImageLoaded}
                                                                                                                    onComplete={this.onLogoCropComplete}
                                                                                                                    onChange={this.onCropChange}
                                                                                                                    circularCrop={true}
                                                                                                                    ruleOfThirds={true}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </Fragment>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </DialogContent>
                                                                                        <DialogActions>
                                                                                            <Button variant="contained" style={{ marginRight: 20 }}
                                                                                                onClick={this.handleImageUploadClose}
                                                                                                color="primary">
                                                                                                <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>Save Changes</Typography>
                                                                                            </Button>
                                                                                        </DialogActions>
                                                                                    </Dialog>

                                                                                    <Dialog
                                                                                        onClose={() => this.handleBannerUploadClose()}
                                                                                        aria-labelledby="customized-dialog-title"
                                                                                        open={this.state.setBannerUploadOpen}
                                                                                        maxWidth='xl'
                                                                                        fullWidth={true}>

                                                                                        <DialogTitle id="customized-dialog-title" onClose={this.handleBannerUploadClose}>

                                                                                        </DialogTitle>
                                                                                        <DialogContent>
                                                                                            <div>
                                                                                                <div style={{ textAlign: 'center' }}>
                                                                                                    <input type="file" name="file" id="file" onChange={this.onSelectBannerFile} style={{ width: 0.1, height: 0.1, opacity: 0, overflow: 'hidden', position: "absolute", zIndex: '1' }} />
                                                                                                    <label className='chooseFile' style={styles.inputLogo} for="file">Click to choose file</label>
                                                                                                </div>
                                                                                                {this.state.bannerSrc && (
                                                                                                    <Fragment>
                                                                                                        <div style={{ maxWidth: 1100, margin: 'auto' }}>
                                                                                                            <ReactCrop
                                                                                                                src={bannerSrc}
                                                                                                                crop={this.state.crop}
                                                                                                                onImageLoaded={this.onImageLoaded}
                                                                                                                onComplete={this.onBannerCropComplete}
                                                                                                                onChange={this.onCropChange}
                                                                                                                ruleOfThirds={true}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </Fragment>
                                                                                                )}
                                                                                            </div>
                                                                                        </DialogContent>
                                                                                        <DialogActions>
                                                                                            <Button variant="contained"
                                                                                                onClick={this.handleBannerUploadClose} color="primary">
                                                                                                <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>Save changes</Typography>
                                                                                            </Button>
                                                                                        </DialogActions>
                                                                                    </Dialog>

                                                                                    <div style={{ display: 'flex', flexWrap: 'wrap', minWidth: 600, justifyContent: 'space-around', position: 'absolute', top: 260, left: 6, margin: 30, height: 250, overflow: 'scroll' }}>
                                                                                        <TextField
                                                                                            style={{ width: 220 }}
                                                                                            id="standard-name"
                                                                                            label="Tassel Email"
                                                                                            onChange={(e) => this.setState({ editedEmail: e.target.value })}
                                                                                            value={this.state.editedEmail}
                                                                                            margin="normal"
                                                                                        />
                                                                                        <TextField
                                                                                            style={{ width: 220 }}
                                                                                            id="standard-name"
                                                                                            label="First name"
                                                                                            onChange={(e) => this.setState({ editedFirstName: e.target.value })}
                                                                                            value={this.state.editedFirstName}
                                                                                            margin="normal"
                                                                                        />
                                                                                        <TextField
                                                                                            style={{ width: 220 }}
                                                                                            id="standard-name"
                                                                                            label="Last name"
                                                                                            onChange={(e) => this.setState({ editedLastName: e.target.value })}
                                                                                            value={this.state.editedLastName}
                                                                                            margin="normal"
                                                                                        />


                                                                                        {this.props.userRoleId !== 8 ?
                                                                                            <InputMask style={{ borderColor: '#949494', borderBottom: 1, width: 220 }}
                                                                                                {...this.props}
                                                                                                mask="+1 999-999-9999"
                                                                                                value={this.state.editedPhoneNumber}
                                                                                                maskChar=" "
                                                                                                onChange={(e) => this.setState({ editedPhoneNumber: e.target.value })}>
                                                                                                {(inputProps) => <TextField {...inputProps} type="tel"
                                                                                                    disableUnderline label="Phone Number" margin="normal" />}
                                                                                            </InputMask>

                                                                                            : null}

                                                                                        {this.props.userRoleId === 8 ?

                                                                                            <TextField
                                                                                                style={{ width: 220 }}
                                                                                                id="standard-name"
                                                                                                label="Year"
                                                                                                value={this.props.gradeName}
                                                                                                margin="normal"
                                                                                                helperText="Only guidance counselors can change your grade"
                                                                                                InputProps={{
                                                                                                    readOnly: true,
                                                                                                }}
                                                                                            />
                                                                                            : null}


                                                                                        {this.props.userRoleId === 8 ?

                                                                                            <TextField
                                                                                                style={{ width: 220 }}
                                                                                                id="standard-name"
                                                                                                label="School"
                                                                                                value={this.props.schoolName}
                                                                                                margin="normal"
                                                                                                helperText="Only guidance counselors can change your school"
                                                                                                InputProps={{
                                                                                                    readOnly: true,
                                                                                                }}
                                                                                            />

                                                                                            : null}

                                                                                    </div>

                                                                                    <IconButton style={{ position: 'absolute', right: '72%' }} variant="outlined"
                                                                                        color="primary" onClick={() => this.handleImageUploadOpen()} >
                                                                                        <FontAwesomeIcon style={{ fontSize: 20 }} icon={faPencilAlt} />
                                                                                    </IconButton>
                                                                                    <IconButton style={{ position: 'absolute', right: '5%', }} variant="outlined"
                                                                                        color="primary" onClick={() => this.handleBannerUploadOpen()} >
                                                                                        <FontAwesomeIcon style={{ fontSize: 20 }} icon={faPencilAlt} />
                                                                                    </IconButton>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </CardContent>
                                                            </Card>

                                                        </Grid>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button variant="contained" color="primary" style={{ marginRight: 20 }}
                                                            onClick={() => this.onSaveChangeClick(this.state.croppedLogo64, this.state.croppedBanner64, this.state.editedFirstName, this.state.editedLastName, this.state.editedEducation, this.state.editedEmail, this.state.editedPhoneNumber)}
                                                        >
                                                            <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>Save Changes</Typography>
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                                <>
                                                    <IconButton style={{ position: 'absolute', right: '17%', marginTop: 10 }}
                                                        variant="outlined" color="primary" onClick={() => this.printProfileOpen()} >
                                                        <FontAwesomeIcon style={{ fontSize: 20 }} icon={faPrint} />

                                                    </IconButton>
                                                    {this.props.personId === this.props.profileId ?

                                                        <IconButton style={{ position: 'absolute', right: '13%', marginTop: 10 }}
                                                            variant="outlined" color="primary" onClick={() => this.handleEditProfileClick()} >
                                                            <FontAwesomeIcon style={{ fontSize: 20 }} icon={faPencilAlt} />
                                                        </IconButton>
                                                        : null}
                                                </>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                                <br style={{ clear: 'both' }} />
                            </CardContent>
                        </Card>


                        <Tabs
                            value={this.state.tabValue}
                            style={{ marginBottom: 10, marginTop: 5, marginLeft: -15 }}
                            onChange={(evt, value) => this.setState({ tabValue: value })}
                            aria-label="Tabs">
                            <Tab label="Individual Learning Plan" />
                            {/*       <Tab label="Work Ready Essential Skills" /> */}
                        </Tabs>


                        {this.state.tabValue === 0 ?

                            <Grid container spacing={2} style={{ display: 'flex', flexDirection: "row" }}>
                                <Grid item xs={8}>
                                    <Grid container spacing={2}>
                                        {(this.props.userRoleId === 8 && this.props.personId === this.props.profileId) ||
                                            (this.props.userRoleId !== 8 && this.props.personId !== this.props.profileId) ?
                                            <Grid item xs={12} >
                                                <Pathway props={this.props} onPathwayLoaded={this.onPathwayLoaded} language={this.state.language} />
                                            </Grid>
                                            : null}
                                        {this.props.profileRoleId < 8 ?
                                            <Grid item xs={12}>
                                                <Bio props={this.props} language={this.state.language} />
                                            </Grid>
                                            : null}
                                        {this.props.profileRoleId >= 8 ?
                                            <Grid item xs={12}>
                                                <Interest props={this.props} onTraitsLoaded={this.onTraitsLoaded} onSubjectsLoaded={this.onSubjectsLoaded} language={this.state.language} />
                                            </Grid>
                                            : null}
                                        {this.props.profileRoleId < 8 ?
                                            <Grid item xs={6}>
                                                <Education props={this.props} language={this.state.language} />
                                            </Grid>
                                            : null}
                                        {this.props.profileRoleId < 8 ?
                                            <Grid item xs={6}>
                                                <Publication props={this.props} language={this.state.language} />
                                            </Grid>
                                            : null}
                                        <Grid item xs={6}>
                                            <Learning props={this.props} onLearningLoaded={this.onLearningLoaded} language={this.state.language} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Skill props={this.props} onSkillLoaded={this.onSkillLoaded} language={this.state.language} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Activity props={this.props} onClubsLoaded={this.onClubsLoaded} language={this.state.language} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Achievement props={this.props} onAwardsLoaded={this.onAwardsLoaded} language={this.state.language} />
                                        </Grid>

                                        {this.props.districtId !== 252 && ((this.props.userRoleId === 8 && this.props.personId === this.props.profileId) ||
                                            (this.props.userRoleId !== 8 && this.props.personId !== this.props.profileId)) ?

                                            <Grid item xs={6}>
                                                <Civic props={this.props} />
                                            </Grid>

                                            : null}

                                        {(this.props.userRoleId === 8 && this.props.personId === this.props.profileId) ||
                                            (this.props.userRoleId !== 8 && this.props.personId !== this.props.profileId) ?

                                            <Grid item xs={6}>
                                                <Resume props={this.props} />
                                            </Grid>

                                            : null}

                                        {(this.props.personId === this.props.profileId) ?

                                            <Grid item xs={6}>
                                                <Recommendation props={this.props} />
                                            </Grid>

                                            : null}

                                        {(this.props.personId === this.props.profileId && this.props.gradeId < 10) ?

                                            <Grid item xs={6}>
                                                <WorkExperienceScreen props={this.props} />
                                            </Grid>

                                            : null}


                                        {(this.props.userRoleId === 8 && this.props.personId === this.props.profileId) ||
                                            (this.props.userRoleId !== 8 && this.props.personId !== this.props.profileId) ?
                                            <Grid item xs={6}>
                                                <Assessment props={this.props} onAssessmentsLoaded={this.onAssessmentsLoaded} language={this.state.language} />
                                            </Grid>
                                            : null}
                                        {this.props.userRoleId === 8 ?
                                            <Grid item xs={6}>
                                                <Work props={this.props} language={this.state.language} />
                                            </Grid>
                                            : null}
                                        <Grid item xs={6}>
                                            <Service props={this.props} />
                                        </Grid>
                                        {(this.props.userRoleId === 8 && this.props.personId === this.props.profileId) ||
                                            (this.props.userRoleId !== 8 && this.props.personId !== this.props.profileId) ?
                                            <Grid item xs={6}>
                                                <Goal props={this.props} language={this.state.language} />
                                            </Grid>
                                            : null}
                                        <Grid item xs={6}>
                                            <Project props={this.props} onProjectsLoaded={this.onProjectsLoaded} language={this.state.language} />
                                        </Grid>
                                        {(this.props.userRoleId === 8 && this.props.personId === this.props.profileId) ||
                                            (this.props.userRoleId !== 8 && this.props.personId !== this.props.profileId) ?
                                            <Grid item xs={6}>
                                                <Gpa props={this.props} language={this.state.language} />
                                            </Grid>
                                            : null}
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>

                                    {(this.props.userRoleId === 8 && this.props.earlyGraduationProgram === 1 && this.props.personId === this.props.profileId) ||
                                        (this.props.userRoleId !== 8 && this.props.earlyGraduationProgram === 1 && this.props.personId !== this.props.profileId) ?
                                        <Checklist />
                                        : null
                                    }

                                    {(this.props.userRoleId === 8 && this.props.personId === this.props.profileId) ||
                                        (this.props.userRoleId !== 8 && this.props.personId !== this.props.profileId) ?
                                        <ILPProgressScreen />
                                        : null
                                    }

                                    {(this.props.userRoleId === 8 && this.props.personId === this.props.profileId) ||
                                        (this.props.userRoleId !== 8 && this.props.personId !== this.props.profileId) ?
                                        <Cluster onClustersLoaded={this.onClustersLoaded} props={this.props} />
                                        : null
                                    }

                                    <Career onCareersLoaded={this.onCareersLoaded} props={this.props} />
                                    <College onCollegesLoaded={this.onCollegesLoaded} props={this.props} />
                                    {this.props.districtId !== 14 && this.props.userRoleId === 8 ?
                                        <Connection props={this.props} language={this.state.language} />
                                        : null
                                    }

                                    {(this.props.userRoleId === 8 && this.props.personId === this.props.profileId) ||
                                        (this.props.userRoleId !== 8 && this.props.personId !== this.props.profileId) ?
                                        <StudentClassrooms props={this.props} language={this.state.language} />
                                        : null
                                    }
                                </Grid>
                            </Grid>
                            : null}
                        {this.state.tabValue === 1 ?
                            <Grid container spacing={2} style={{ display: 'flex', flexDirection: "row" }}>
                                <Grid item xs={8}>
                                    <Grid container spacing={2}>

                                        <Grid item xs={6}>
                                            <Adaptability props={this.props} />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Communication props={this.props} />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Diligence props={this.props} />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <DrugFree props={this.props} />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Initiative props={this.props} />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Knowledge props={this.props} />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Reliability props={this.props} />
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>

                                    <WorkReady />

                                </Grid>
                            </Grid>
                            : null}
                    </Grid>

                </Grid>

            </div>
        );
    }
}

function readFile(file) {
    return new Promise(resolve => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

ProfileScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        personId: state.auth.personId,
        username: state.auth.username,
        token: state.auth.token,
        taskId: state.task.taskId,
        userRoleId: state.auth.userRoleId,
        profileId: state.profile.profileId,
        profileRoleId: state.profile.profileRoleId,
        districtId: state.profile.districtId,
        gradeId: state.profile.gradeId,
        gradeName: state.profile.gradeName,
        firstName: state.profile.firstName,
        lastName: state.profile.lastName,
        schoolId: state.profile.schoolId,
        schoolName: state.profile.schoolName,
        earlyGraduationProgram: state.profile.earlyGraduationProgram,
        selectedLanguage: state.language.selectedLanguage,
        announcementAlert: state.alert.announcementAlert
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSetProfile: (profileId, firstName, lastName, gradeId, gradeName, districtId, districtName, schoolId, schoolName, profileRoleId, earlyGraduationProgram) => { dispatch(setProfile(profileId, firstName, lastName, gradeId, gradeName, districtId, districtName, schoolId, schoolName, profileRoleId, earlyGraduationProgram)); },
        onLogout: () => { dispatch(logout()); },
        onSetLanguage: (selectedLanguage) => { dispatch(setLanguage(selectedLanguage)); }
    }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProfileScreen)));